import React, { Component } from 'react';
import Header from "../layout/header"
import Footer from "../layout/footer"
import Banner from "./home-banner"
import ProvenResults from "./home-proven-results"
import HomeOurSolutions from "./home-our-solutions"
import HomeSuccess from "../home/digital-branding-success-stories"
import AboutTakeNextStep from './about-take-next-step';
import $ from "jquery";

export default class home extends Component {
  componentDidMount(){
    document.getElementById("home-menu").classList.add("active");
    $("#solutionsButton").click(function () {
      $('html, body').animate({
        scrollTop: $("#purplehealthfeatureheadID").offset().top
    }, 100);
    })
  }
  render() {
    return (
      <div className="ph-main-wrapper">
          <Header />
          <Banner></Banner>
          <ProvenResults />
          <HomeOurSolutions />
          <HomeSuccess />
          <AboutTakeNextStep />
          <Footer></Footer>
      </div>
    )
  }
}
