import React, { Component } from "react";
import "../css/purpleresponsive.css";
import Footerlogo from "../image/theme/purplelogo.png";
import SocialFB from "../image/theme/facebook-logo.png";
import SocialInsta from "../image/theme/instagram.png";
import SocialTWITR from "../image/theme/twitter-2.png";
import SocialUTUBE from "../image/theme/youtube.png";
import { Link } from "react-router-dom";
import { apiCalling } from "../apiService";
import WhatsApp from '../home/whatsapp'
import logoImg from '../image/theme/darker-logo.png'
import Fb from '../image/theme/facebook.png'
import Insta from '../image/theme/insta.png'
import FooterImg from '../image/theme/footer-india.png'
import Linkdn from '../image/theme/linkdn.png'
import { setIsAuthenticated } from "../redux/reducers/authSlice";
export default class footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      warningTime: 3000 * 60 * 10,
      signoutTime: 3000 * 60 * 15,
    };
  }
 
  componentDidMount() {
    this.events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
    ];

    for (var i in this.events) {
      window.addEventListener(this.events[i], this.resetTimeout);
    }

    this.setTimeout();
  }
 
  scrollToSolution = () => {
    const solutionComponent = document.getElementById('solution-component');
    if (solutionComponent) {
      solutionComponent.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      // If the Solutions component is not found (for example, on the About page), redirect to the home page
      window.location.href = '/';
    }
  };
  
  clearTimeoutFunc = () => {
    if (this.warnTimeout) clearTimeout(this.warnTimeout);

    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
  };

  setTimeout = () => {
    this.warnTimeout = setTimeout(this.warn, this.state.warningTime);
    this.logoutTimeout = setTimeout(this.logout, this.state.signoutTime);
  };

  resetTimeout = () => {
    this.clearTimeoutFunc();
    this.setTimeout();
  };

  logout = () => {
    this.destroy();
    this.props.dispatch(setIsAuthenticated(false))

  };

  destroy = () => {
    //clear the session
    //browserHistory.push('/');
    //localStorage.clear();
    const logoutReq = {
      method: "POST",
      body: JSON.stringify({
        functionName: "logout",
        siteId: localStorage.getItem("siteId"),
        userId: localStorage.getItem("userId"),
      }),
    };
    apiCalling(logoutReq).then((data) => {
      if (data.success == "1") {
        localStorage.clear();
        window.sessionStorage.clear()
        window.location.href = "/";
        alert("Login Timeout");
      }
    });
    //window.location.assign("/");
  };
  render() {
    return (
      <div className="ph-footer">
        <div className="container-lg">
          <footer className="d-flex flex-wrap justify-content-between">
            <div className="col-lg-4 col-12 col-sm-12 ph-pl-0">
              <div className="ph-footer-logo">

              <img src={logoImg} className="img-fluid" alt="logo" width="150" height="54"></img>
              </div>
              <div>
                <div className="ph-reg-address">
                  Registered Address :
                </div>
                <div className="ph-main-address">
                  Irish Isle Luxury Villa, Villa #38, Koliyakode, Pothencode, Trivandrum, Kerala, India - 695589
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-12 col-sm-12 ph-pl-0">
              <div className="mb-3 mt-sm-2">
              <a className="ph-links-footer">Links</a>
              </div>
              <div className="d-flex flex-wrap">
             
                <div className="col-lg-3 col-sm-4 px-0">
                  
                  <ul className="nav flex-column">
                  
                    <li className="nav-item ph-footer-navs">
                      <Link to="/" className="nav-link p-0 ph-nav-item">Home</Link>
                      </li>
                    <li className="nav-item ph-footer-navs">
                    <Link to="/about" className="nav-link p-0 ph-nav-item">About Us</Link>
                      </li>
                    <li className="nav-item ph-footer-navs">
                    <Link to="" className="nav-link p-0 ph-nav-item" onClick={this.scrollToSolution}>Solutions</Link>
                      </li>
                    <li className="nav-item ph-footer-navs">
                    <Link to="/contactus" className="nav-link p-0 ph-nav-item">Contact Us</Link>
                      </li>
                  </ul>
                </div>
                <div className="col-lg-5 pr-0 col-sm-4 px-0">
                  <ul className="nav flex-column">
                    {/* <li className="nav-item ph-footer-navs"><a href="#" className="nav-link p-0 ph-nav-item">Career</a></li> */}
                    <li className="nav-item ph-footer-navs"><a href="/blogs" className="nav-link p-0 ph-nav-item">Blogs</a></li>
                    <li className="nav-item ph-footer-navs"><a href="/terms-conditions" className="nav-link p-0 ph-nav-item">Terms & Conditions</a></li>
                    <li className="nav-item ph-footer-navs"><a href="/privacy-policy" className="nav-link p-0 ph-nav-item">Privacy Policy</a></li>
                  </ul>
                </div>
                <div className="col-lg-4 pl-0 col-sm-4 px-0">
                  <ul className="nav flex-column">
                  <li className="nav-item ph-footer-navs"><a href="/news" className="nav-link p-0 ph-nav-item">News</a></li>
                    <li className="nav-item ph-footer-navs"><Link to="/resources" className="nav-link p-0 ph-nav-item">Success Stories </Link></li>
                    <li className="nav-item ml-0">
                    <Link className="nav-link p-0 ph-nav-item"
                      to="/login"
                    >
                        {window.localStorage.getItem("isLogin") == "true"
                          ? "Logout"
                          : "Doctor Login"}
                    </Link>
                  </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-12 px-0 col-sm-12 ph-footer-with-logo">
              <ul className="nav">
                <li className="nav-item">
                  <a target="_blank" href="https://www.facebook.com/gopurplehealth" className="nav-link px-0 ph-footer-icons" >
                    <img src={Fb} alt="fb" width="50" height="50"/>
                  </a>
                  </li>
                <li className="nav-item">
                  <a target="_blank" href="https://www.instagram.com/gopurplehealth?igsh=cm02a21zdjdrb3d6" className="nav-link px-0 ph-footer-icons" >
                  <img src={Insta} alt="fb" width="50" height="50"/>
                  </a>
                  </li>
                <li className="nav-item">
                  <a target="_blank" href="https://www.linkedin.com/company/purplehealth/mycompany/" className="nav-link px-0 ph-footer-icons" >
                  <img src={Linkdn} alt="fb" width="50" height="50"/>
                  </a>
                  </li>
              </ul>
              <div className="ph-footer-right">
                <img src={FooterImg} alt="FooterImg" width="80" height="40"/>
              </div>
            </div>
           
          </footer>
          <WhatsApp/>
        </div>
      </div>

    );
  }
}
