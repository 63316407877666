import React, { useState, useEffect, Suspense, useRef } from "react";
import Header from "../layout/header";
import { awsAPIUrlcommon, apiCalling } from "../apiService";
import Footer from "../layout/footer";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import $ from "jquery";
// import "../css/";
const PollHistory = () => {
    const location = useLocation();
    let data
    let siteId = location && location.state && location.state.siteId ? location.state.siteId : ""
    const [importData, setImportData] = useState([])
    const [pollListData, setPollListData] = useState([]);
    useEffect(() => {
        if (location && location.state) {
            ({ data, siteId } = location.state);
            getFullListData(data)
            exportExcelFunction(data)
        }
    }, []);
    const navigate = useNavigate();


    const handleClickEdit = (data) => {
        navigate('/adminManagement/create-poll', {
            state: data
        });
    };



    const exportExcelFunction = (data) => {
        $(".loader").show();
        const apiJson = {
            method: "POST",
            body: JSON.stringify({
                "functionName": "pollDataExport",
                "pollId": data
            }),
        };
        awsAPIUrlcommon(apiJson).then((data) => {
            if (data.success === "1") {
                setImportData(data.result)
                $(".loader").hide();
            } else {
                $(".loader").hide();
            }
        })
            .catch((error) => {
                console.error("Error sending notification:", error);
                $(".loader").hide()
                // alert("An error occurred while Getting Details");
            });
    };
    const getFullListData = (data) => {
        $(".loader").show();
        const apiJson = {
            method: "POST",
            body: JSON.stringify({
                "functionName": "pollHistory",
                "pollId": data
            }),
        };
        awsAPIUrlcommon(apiJson).then((data) => {
            if (data.success === "1") {
                setPollListData(data.result[0])
                $(".loader").hide();
            } else {
                alert("Somethingwent Wrong While Fetching Data")
                $(".loader").hide();
            }
        }).catch((error) => {
            console.error("Error sending notification:", error);
            $(".loader").hide()
            alert("An error occurred while Getting Details");
        });
    };
    function convertToIST(timestamp) {
        const utcDate = new Date(timestamp);
        const offsetMinutes = utcDate.getTimezoneOffset();
        const istOffset = 330;
        const istTime = new Date(utcDate.getTime() + (offsetMinutes + istOffset) * 60 * 1000);
        const options = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        };

        const istTimeString = istTime.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', ...options });

        return istTimeString;
    }
    function formatDate(timestamp) {
        if (timestamp) {
            const date = new Date(timestamp);
            const day = date.getUTCDate().toString().padStart(2, '0');
            const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
            const year = date.getUTCFullYear();
            const formattedDate = `${day}-${month}-${year}`;
            return formattedDate;
        } else { return "NoData Available" }
    }
    const exportExcelFunctionClickFunction = () => {
        try {
            if (!importData || !Array.isArray(importData) || importData.length === 0) {
                alert("No data available to export.");
                console.error('Imported data is not in the expected format or is empty');
                return;
            }
    
            const wb = XLSX.utils.book_new();
            const questionsMap = {};
            importData.forEach(data => {
                if (data.questionId && data.pollQuestion) {
                    questionsMap[data.questionId] = data.pollQuestion;
                }
            });
    
            const questionIds = Object.keys(questionsMap);
            const headers = ['Created On', 'User Name', 'Email', 'Mobile Number', ...questionIds.map(id => questionsMap[id])];
            const wsData = [headers];
    
            importData.forEach(data => {
                const answerParts = [];
    
                if (data.pollQuestionsAnswer) {
                    answerParts.push(`Answer: ${data.pollQuestionsAnswer}`);
                }
                if (data.textAnswer) {
                    answerParts.push(`Text: ${data.textAnswer}`);
                }
    
                const answer = answerParts?.join(', '); 
    
                const row = [
                    convertToIST(data?.userPollCreatedOn),
                    data.userName,
                    data.emailId,
                    data.mobileNumber,
                    ...questionIds.map(id => id === data?.questionId.toString() ? answer : "")
                ];
                wsData.push(row);
            });
    
            if (wsData.length > 1) {
                const ws = XLSX.utils.aoa_to_sheet(wsData);
                const sheetName = importData[0].pollName || 'Poll_Details';
                const truncatedSheetName = sheetName.length > 31 ? sheetName.slice(0, 31) : sheetName;
                XLSX.utils.book_append_sheet(wb, ws, truncatedSheetName);
                const filename = `${sheetName}_Details.xlsx`;
                XLSX.writeFile(wb, filename);
            } else {
                alert('No data available to export.');
            }
        } catch (error) {
            alert('An error occurred while exporting data. Please try again.');
            console.error(error);
        }
    };
    
    
    
    
    
    
    return (
        <div className="dashboard-doctorOuter">
            <Suspense fallback={<div></div>}>
                <section>
                    <Header />
                    <div className="loader"></div>
                    <div className="container mb-5">
                        <div>
                            <ul className="breadcrum-adminMangmnt">
                                {window.localStorage.getItem("isLogin") == "true" && window.localStorage.getItem("role") === "ROLE_ADMIN" ? (
                                    <li>
                                        <a href="/admin/dashboard">Dashboard</a>
                                    </li>) : ""}
                                <li>
                                    <a href="/adminManagement/PollList" >
                                        Poll List
                                    </a>
                                </li>
                                <li>
                                    <a href="" className="current">
                                        Poll History
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="head-hospMangmntList mb-4">Poll History Details
                            <div className="AddNewHspbtnDiv w-auto pull-right">
                                <button
                                    // to={{
                                    //     pathname: "/adminManagement/create-poll",
                                    //     state: {
                                    //         data: "", siteId: siteId
                                    //     },
                                    // }}
                                    onClick={() => handleClickEdit({ data: "", siteId: siteId })}
                                    // to={
                                    //   "/adminManagement/create-poll"}
                                    className="addnewhspbtn mr-3"
                                > Create New Poll</button>
                                {/* <a href="/adminManagement/create-poll" className="addnewhspbtn mr-3">
                                    Add New Poll
                                </a> */}
                                {pollListData && pollListData.PollId &&
                                    <span className="addnewhspbtn mr-3" onClick={exportExcelFunctionClickFunction} >
                                        Export Excel
                                    </span>}
                            </div>
                        </div>
                        <div className="row poll_history">
                            <div className="col-md-12">
                                <div className="card mb-3">
                                    <div className="card-header py-1 border-0">
                                        <p className=""> <span className="span_medium">Title </span>{pollListData && pollListData.pollName}</p>
                                        {pollListData && pollListData.pollDescription &&
                                            <p className=""><span className="span_medium"> Description </span>{pollListData.pollDescription}</p>}
                                        <p className=""><span className="span_medium"> Created On </span>{formatDate(pollListData?.pollCreatedOn)}</p>
                                        <p className=""><span className="span_medium"> Start Date </span>{formatDate(pollListData?.pollStartDate)}</p>
                                        <p className=""><span className="span_medium"> End Date  </span>{formatDate(pollListData?.pollEndDate)}</p>
                                    </div>
                                </div>
                            </div>
                            {pollListData && pollListData.pollDetails && pollListData.pollDetails.map((data, i) => (
                                <div className="col-md-12 mb-3">
                                    <div className="card">
                                        <div className="card-body p-0">
                                            <div className="card border-0">
                                                <div className="card-header py-1">
                                                    <p className="mb-0"> <span className="span_medium"> Question {i + 1} :</span> {data.pollQuestions}</p>
                                                </div>
                                                <div className="card-body pb-0 pt-2">
                                                    <p className=""><span className="span_medium">Question Answer Type : </span>{data.questionAnswerType}</p>
                                                    {/* ////////////////////////////// */}

                                                    {data.userPollDetails.map((data, j) => (
                                                        <div className="card mb-2 mt-2">
                                                            <div className="card-header py-1 border-0">
                                                                <div className="row">
                                                                    <p className="mb-0 col-md-6" style={{ color: "#a56565" }}> <span className="span_medium">  Options {1 + j} :</span> {data.answer?data.answer:"Text Answers"}</p>
                                                                    <p className="mb-0 col-md-6 text-right"> <span className="span_medium">  Count:</span>{data.totalCount}</p>
                                                                </div>
                                                                <div className="row">
                                                                    {data.pollUsers.map((item, k) => (
                                                                        <div className="col-md-4 card mb-2 mt-2 p-0" style={{ borderColor: "#2278c3" }}>
                                                                            <div className="card-header py-1 border-0 ">
                                                                                <p className="mb-0"> <span className="span_medium">User Name {1 + k} :</span> {item.userDisplayName}</p>
                                                                                <p className="mb-0"> <span className="span_medium"> MobileNumber: </span>{item.mobile_number}</p>
                                                                                {item?.textAnswer &&
                                                                                    <p className="mb-0"> <span className="span_medium"> Answer : </span>{item.textAnswer}</p>
                                                                                }
                                                                            </div>
                                                                        </div>))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                    {/* /////////////////////////////////////////// */}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </Suspense>
            <Suspense fallback={<div></div>}>
                <section>
                    <Footer />
                </section>
            </Suspense>
        </div>
    );
}
export default PollHistory;