import React, { Suspense, useState, useEffect } from "react";
import UploadImg from '../image/reliance/upload.png';
import Header from "./header";
import Footer from "./footer";
import RhFileuploadList from "./rh-file-upload-list";
import RhFileuploadReport from "./rh-file-upload-report";
import * as XLSX from 'xlsx';
import { awsAPIUrlcommonToken, apiCalling } from "../apiService";
import AWS from "aws-sdk";
import { error } from "jquery";
import csv from "../image/icons/csv.png";
import { saveAs } from 'file-saver';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const RhFileupload = () => {
  const [tableDataExcel, settableDataExcel] = useState([]);
  const [itemsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [exPortData, setExPortData] = useState([])
  const [uploaded, setUploaded] = useState(false)
  const [isLoader, setIsLoader] = useState(false)
  const [data, setData] = useState([]);
  const [uploadFlag, setUploadFlag] = useState(false)
  const [fileName, setFileName] = useState("")
  const [imageDeatils, setimageDeatils] = useState({
    AWSAccessKeyId: "",
    s3bucketAccessKey: "",
    region: "us-east-1",
    bucket: "rhealthfiles",
    bucketFolder: "",
  })

  useEffect(() => {
    imageUploadHandler()
  }, [])

  useEffect(() => {
    setTimeout(() => {
    }, 2000);
  }, [tableDataExcel])

  const getTableData = (childData) => {
    settableDataExcel(childData);
  };
  const ExportAllData = () => {
    // console.log("🚀 ~ ExportAllData ~ tableDataExcel:", tableDataExcel)
    if(tableDataExcel.length > 0 ){
      toast.success("Download processing...")
    }else {
      toast.error("No data found.")
    }
    let exportData = [];
    let exportTotalCount = "";
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "doctorConsultationReport",
        offset: 0,
        max: ""
      })
    };
    awsAPIUrlcommonToken(apiJson)
      .then((data) => {
        if (data.success === "1") {
          exportData = data.result
          exportTotalCount = data.totalCount;
          ExportAllDataExcel(exportData, exportTotalCount)
        } else {
          // console.log(data.errorMessage);
          exportData = []
          exportTotalCount = data.totalCount;
        }
      })
      .catch((error) => {
        exportData = []
        console.error('Error fetching data:', error);
    });

  }
  
  const ExportAllDataExcel = (exportData, exportTotalCount) => {
    if (exportTotalCount > 0) {
      const worksheet = XLSX.utils.json_to_sheet(exportData);
      let header = [
        "Id",
        "Doctor Name",
        "Patient Name",
        "Speciality",
        "Patient Mobile Number",
        "Date",
        "Month",
        "Day",
        "Transaction Id",
        "Service",
        "Transaction Date",
        "Time",
        "Call Status",
        "feedback",
        "Policy No.",
        "Employee Id",
        "Status",
        "Created On",
        "Modified On",
        "Rha Dashboard Id"
    ];
      const columnWidths = [
        { wch: 8 }, // Width for "Id"
        { wch: 20 }, // Width for "Doctor Name"
        { wch: 28 }, // Width for "Patient Name"
        { wch: 16 }, // Width for "Speciality"
        { wch: 16 }, // Width for "Patient Mobile Number"
        { wch: 16 }, // Width for "Date"
        { wch: 10 }, // Width for "Month"
        { wch: 12 }, // Width for "Day"
        { wch: 12 }, // Width for "Transaction Id"
        { wch: 15 }, // Width for "Service"
        { wch: 24 }, // Width for "Transaction Date"
        { wch: 20 }, // Width for "Time"
        { wch: 20 }, // Width for "Call Status"
        { wch: 20 }, // Width for "Feedback"
        { wch: 30 }, // Width for "Policy No."
        { wch: 30 }, // Width for "Employee Id"
        { wch: 10 }, // Width for "Status"
        { wch: 30 }, // Width for "Created On"
        { wch: 30 }, // Width for "Modified On"
        { wch: 30 }, // Width for "Rha Dashboard Id"
      ];
      // Set column widths in the worksheet
      worksheet['!cols'] = columnWidths;
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      XLSX.utils.sheet_add_aoa(worksheet, [header], { origin: "A1" });
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const filename = 'data.xlsx';
      saveAs(new Blob([excelBuffer], { type: 'application/octet-stream' }), filename);
    }
    else {
      toast.error("Doctor Consultation Report is empty!")
    }
  }
  const imageUploadHandler = () => {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "imagePath",
      }),
    };
    // console.log("🚀 ~ imageUploadHandler apiCalling ~ apiJson:", apiJson)

    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        setimageDeatils((props) => ({
          ...props,
          s3bucketAccessKey: data.configPathDetails[0].s3bucketAccessKey,
          AWSAccessKeyId: data.configPathDetails[0].s3bucketSecretKey,
          bucketFolder: data.configPathDetails[0].S3BucketMainFolder,
        }));
      }
    });
  };

  const FileUpladChange = (e, index) => {
    const file = e.target.files[0];
    //FileUplodRead(e)
    setIsLoader(true)
    fileUpload(file, index);
  }
 
  const fileUpload = (file, index) => {
    const S3BucketMainFolder = "";
    let fileName = file.name
    let last = fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length);
    fileName = `consultaion-report-template_${new Date().getTime()}.${last}`;

    setFileName(fileName)
    const fullFilePath = S3BucketMainFolder + fileName;
    AWS.config.update({
      accessKeyId: imageDeatils.s3bucketAccessKey,
      secretAccessKey: imageDeatils.AWSAccessKeyId,
      region: imageDeatils.region,
    });

    const bucketParams = {
      Bucket: `${imageDeatils.bucket}/${imageDeatils.bucketFolder}/RHADashboard`,
    };
    const s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
    s3.upload(
      {
        Key: fullFilePath,
        Body: file,
        ACL: "public-read",
      },
      (err, data) => {
        if (err) {
          alert("There was an error uploading your Excel: ", err.message);
        } else if (data) {
          setUploaded(true)
          setIsLoader(false)
        }
      }
    );
  };
  const fileUploadRHADashboard = () => {
    const postData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "rhaDashboardFileNameSave",
        fileName: fileName,
        userId: window.localStorage.getItem("userId")
      })
    }
    // console.log("🚀 ~ fileUploadRHADashboard ~awsAPIUrlcommonToken- postData:", postData)
    awsAPIUrlcommonToken(postData).then((data) => {
      //  console.log("🚀 ~ awsAPIUrlcommonToken ~ data:", data)
      toast.success("The update will take a few minutes....")
      if (data.success === "1") { 
        data?.result?.rhaDashboardFilesId && fileUploadReport(data.result.rhaDashboardFilesId)
        setUploaded(false)
      }
      else {
        setUploaded(false)
        toast.error(data.errorMessage)
      }
    }).catch((error) => {
      console.error("Error in getting uploaded data:", error);
    })
  }


  const fileUploadReport = (rhaDashboardFilesId) => {
    setUploadFlag(false)
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "uploadDoctorConsultationReport",
        rhaDashboardFilesId: rhaDashboardFilesId
      })
    }
    // console.log("🚀 ~ fileUploadReport awsAPIUrlcommonToken ~ apiJson:", apiJson)
    awsAPIUrlcommonToken(apiJson).then((data) => {
      if (data.success === "1") { 
        setUploadFlag(true)
      }
      else {
        setUploadFlag(true)
      }
    }).catch((error) => {
      setUploadFlag(true)
      console.error("Error in getting uploaded data:", error);
    });
  }

  return (
    <div className="purpleWrap">
      <Suspense fallback={<div>Waiting...</div>}>
      <ToastContainer />
        <section>
          <Header />
          {/* <div className="loader"></div> */}
          <div className="container">
            <div className="main-wrapper1">
              <div className="col-md-12 p-0">
                <ul className="breadcrumb">
                  <li>
                    <a href={"/reliance/dashboard"}>Dashboard</a>
                    <i className="fa fa-chevron-right"></i>
                  </li>
                  <li>
                    <a href={"/reliance/rha-dashboard"}>RHA Dashboard</a>
                    <i className="fa fa-chevron-right"></i>
                  </li>
                  <li> File Upload</li>
                </ul>
              </div>
              <div className="row py-2 align-items-center mb-3">
                <div className="col-lg-8 col-4">
                  <div className="form-head mb-0 border-0 mb-0 pb-0"> File Upload </div>
                </div>
                {tableDataExcel.length > 0 &&
                <div className="col-lg-4 text-right col-8">
                  <a  className="cursor-pointer" onClick={(e) => { ExportAllData() }} >
                    <img src={csv} alt="csv" width="25" title="Export CSV" className="mt-1" />
                  </a>
                </div>
                }
              </div>
              <div className="file_upload_div">
                <div className="file_upload_innerdiv">
                 {uploaded ?
                    <div className="file_upload_table_div">
                      <div className="table_fileupload_btn">
                        <button type="button" className="file_upload"
                          onClick={() => fileUploadRHADashboard()}>Upload</button>
                        <button type="button" className="file_upload_cancel" >Cancel</button>
                      </div>
                      {/* <RhFileuploadReport /> */}
                    </div>
                    :
                    <div className="file_upload_inputfile">
                      <div className="file_upload_divtag">
                        <img src={UploadImg} className="file_upload_img" alt="upload" />
                      </div>
                      <div className="file_drag_drop">
                        <span className="span_drag_drop">Drag & Drop your file here</span>
                        <span className="span_drag_drop_or">Or</span>
                      </div>
                      <div className="file_upload_btndiv">
                        <button className="file_upload_btn">Browse File</button>
                      </div>
                      <input type="file" className="file_upload_input"
                        onChange={(e) => FileUpladChange(e, "1")}
                        accept=".xlsx, .xls" />
                    </div>
                  }
                  {isLoader &&
                      <div className="loader d-block"></div>}
                </div>
              </div>
              <RhFileuploadList key={uploadFlag} sendDataToParent={getTableData} />
            </div>
          </div>
          <Footer />
        </section>
      </Suspense>
    </div>
  );
};

export default RhFileupload;
