import React, { Component } from "react";
import $ from "jquery";
import { KJUR, b64utoutf8 } from 'jsrsasign';
import { apiCalling } from "../apiService";
import { setIsAuthenticated } from "../redux/reducers/authSlice";
import { connect } from "react-redux";
 class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      loginpassword: "",
      otp: ""
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };
  generateToken = (payload) => {
    return new Promise((resolve, reject) => {
        try {
            const header = { alg: 'HS256', typ: 'JWT' };
            const sHeader = JSON.stringify(header);
            const sPayload = JSON.stringify(payload);
            const hmacKey = { utf8: process.env.REACT_APP_JWT_SECRET };
            const token = KJUR.jws.JWS.sign('HS256', sHeader, sPayload, hmacKey);
            resolve(token);
        } catch (error) {
            reject(error);
        }
    });
};
  componentDidMount() {
    let self = this;
    localStorage.setItem("siteId", "22");
    $("#Loginsubmit").click(function () {
      $("#loginpassword").val("");
      $("#otp").val("");
      let username = self.state.username;
      let loginpassword = self.state.loginpassword;
      var loginErrorMsg = "";
      let formIsValid;
      $("#loginErrorMsg").text("");
      var characters = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      var email = $("#username").val();
      var splitemail = email.split(/\r|\r\n|\n/);
      var phoneno = /^[0-9]*$/;
      var emailcount = splitemail.length;
      if (!username) {
        loginErrorMsg = "Please enter registered Email Id or Mobile Number";
        formIsValid = false;
      }
      else if (characters.test(splitemail[emailcount - 1]) == false && $("#username").val() != "" && !phoneno.test(username)) {
        loginErrorMsg = "Invalid Email Id";
        $("#username").focus();
        formIsValid = false;
      }
      else if (username.includes('@')) {
        $("#username").hide();
        $("#loginpassword").show();
        $("#Loginsubmit").hide();
        $("#OTPLogin").hide();
        $("#PasswordLogin").show();
      }
      else if (!username.includes('@') && username.length != 10 && phoneno.test(username)) {
        loginErrorMsg = "Invalid Mobile Number";
        formIsValid = false;
      }
      else if (!username.includes('@') && username.length == 10) {
        let mobileNumber = username;
        this.generateToken({
          username: mobileNumber,
          password: loginpassword,
      }).then(token => {
        // const token = this.generateToken({
        //   username: mobileNumber,
        //   password: loginpassword,
        // });
        const loginOTPRequest = {
          method: "POST",
          body: JSON.stringify({
            functionName: "login",
            token: token,
            siteId: localStorage.getItem("siteId"),
            otp: "",
          }),
        };
        apiCalling(loginOTPRequest).then((data) => {
          if (data.success == "0") {
            $("#loginErrorMsg").text("Mobile Number is not registered");
            formIsValid = false;
            return false;
          }
          else if (data.success == "1") {
            $("#username").hide();
            $("#loginpassword").hide();
            $("#otp").show();
            $("#otp").focus();
            $("#Loginsubmit").hide();
            $("#PasswordLogin").hide();
            $("#OTPLogin").show();
            $("#loginErrorMsg").text("OTP has been sent to your Mobile Number");
            setTimeout(function () { $("#resendOTP").show(); }, 30000);
          }
          else {
            formIsValid = true;
          }
        });
      }).catch(error => {
        console.error("Token Generation Error:", error);
    });
      }
      else {
        formIsValid = true;
      }
      if (!formIsValid) {
        $("#loginErrorMsg").text(loginErrorMsg);
        messageClear();
        return false;
      }
    });
    $("#PasswordLogin").click(function () {
      let username = self.state.username;
      let loginpassword = self.state.loginpassword;
      var loginErrorMsg = "";
      let formIsValid;
      $("#loginErrorMsg").text("");
      if (!loginpassword) {
        loginErrorMsg = "Password required";
        formIsValid = false;
      }
      else {
        formIsValid = true;
      }
      if (!formIsValid) {
        $("#loginErrorMsg").text(loginErrorMsg);
        return false;
      }
      else {
        $("#loginErrorMsg").text("");
        this.generateToken({
          username: username,
          password: loginpassword,
      }).then(token => {
        // const token = this.generateToken({
        //       username: username,
        // password: loginpassword,
        // });
        const PasswordLoginRequest = {
          method: "POST",
          body: JSON.stringify({
            functionName: "login",
            token:token,
            siteId: localStorage.getItem("siteId"),
            otp: "",
          }),
        };

        apiCalling(PasswordLoginRequest).then((data) => {
          if (data.success == "1") {
            $("#loginErrorMsg").text("Login Successfully");
            if (data.userData) {
              localStorage.setItem("userId", data.userData[0].userId);
              localStorage.setItem("emailId", data.userData[0].emailId);
              localStorage.setItem("userName", data.userData[0].userName);
              localStorage.setItem("isLogin", "true");
              this.props.dispatch(setIsAuthenticated(true))
              localStorage.setItem(
                "mobileNumber",
                data.userData[0].mobileNumber
              );
              localStorage.setItem("role", data.userData[0].role);
              if (data.userData[0].role == "ROLE_DOCTOR") {
                $(".layoutDoctorMenuTop").show();
              }
              if (data.userData[0].role == "ROLE_USER") {
                $(".layoutPatientMenuTop").show();
              }
              setTimeout(() => {
                $("#loginMenu").hide();
                $("#signInWrap").hide();
                $("#loginErrorMsg").text("");
              }, 2000);
            }
          } else {
            $("#loginErrorMsg").text(data.errorMessage);
            $("#username").show();
            $("#loginpassword").hide();
            $("#otp").hide();
            $("#username").focus();
            $("#Loginsubmit").show();
            $("#PasswordLogin").hide();
            $("#OTPLogin").hide();
            messageClear();
          }
        });
      }).catch(error => {
        console.error("Token Generation Error:", error);
    });
      }

    });
    $("#OTPLogin").click(function () {
      let username = self.state.username;
      let otp = self.state.otp;
      let loginpassword = "";
      var loginErrorMsg = "";
      let formIsValid;
      $("#loginErrorMsg").text("");
      if (!otp) {
        loginErrorMsg = "OTP required";
        formIsValid = false;
      }
      else {
        formIsValid = true;
      }
      if (!formIsValid) {
        $("#loginErrorMsg").text(loginErrorMsg);
        return false;
      }
      else {
        $("#loginErrorMsg").text("");
        this.generateToken({
          username: username,
          password: loginpassword,
      }).then(token => {
    //     const token = this.generateToken({
    //       username: username,
    //           password: loginpassword,
    // });
        // const token = jwt.sign(
        //   {
        //     username: username,
        //     password: loginpassword,
        //   },
        //   jwtSecret()
        // );

        const OTPVerifiedLoginRequest = {
          method: "POST",
          body: JSON.stringify({
            functionName: "login",
            token:token,
            siteId: localStorage.getItem("siteId"),
            otp: otp,
          }),
        };

        apiCalling(OTPVerifiedLoginRequest).then((data) => {
          if (data.success == "1") {
            $("#loginErrorMsg").text("Login Successfully");
            if (data.userData) {
              localStorage.setItem("userId", data.userData[0].userId);
              localStorage.setItem("emailId", data.userData[0].emailId);
              localStorage.setItem("userName", data.userData[0].userName);
              localStorage.setItem("isLogin", "true");
              localStorage.setItem(
                "mobileNumber",
                data.userData[0].mobileNumber
              );
              localStorage.setItem("role", data.userData[0].role);
              if (data.userData[0].role == "ROLE_DOCTOR") {
                $(".layoutDoctorMenuTop").show();
              }
              if (data.userData[0].role == "ROLE_USER") {
                $(".layoutPatientMenuTop").show();
              }
              setTimeout(() => {
                $("#loginMenu").hide();
                $("#signInWrap").hide();
                $("#loginErrorMsg").text("");
              }, 2000);
            }
          } else {
            $("#loginErrorMsg").text(data.successMessage);
            setTimeout(function () { $("#resendOTP").show(); }, 30000);
            messageClear();
          }
        });
      }).catch(error => {
        console.error("Token Generation Error:", error);
    });
      }
    });

    $("#resendOTP").click(function () {
      let formIsValid;
      $("#loginErrorMsg").text("");
      let mobileNumber = self.state.username;
      let loginpassword = "";
      this.generateToken({
        username: mobileNumber,
        password: loginpassword,
    }).then(token => {
  //     const token = this.generateToken({
  //       username: mobileNumber,
  //        password: loginpassword,
  // });
      const ResendOTPRequest = {
        method: "POST",
        body: JSON.stringify({
          functionName: "login",
          token: token,
          siteId: localStorage.getItem("siteId"),
          otp: "",
        }),
      };
      apiCalling(ResendOTPRequest).then((data) => {
        if (data.success == "0") {
          $("#loginErrorMsg").text("Mobile Number is not registered");
          formIsValid = false;
          return false;
        }
        else if (data.success == "1") {
          $("#username").hide();
          $("#loginpassword").hide();
          $("#otp").show();
          $("#otp").focus();
          $("#Loginsubmit").hide();
          $("#PasswordLogin").hide();
          $("#OTPLogin").show();
          $("#resendOTP").hide();
          $("#loginErrorMsg").text("OTP has been sent to your Mobile Number");
        }
        else {
          formIsValid = true;
        }
      });
    }).catch(error => {
      console.error("Token Generation Error:", error);
  });
    })
    function messageClear() {
      setTimeout(() => {
        $("#loginErrorMsg").text("");
      }, 5000);
    }
  }

  render() {
    return (
      <div className="signInWrap" id="signInWrap">
        <div className="signInHead">
          <h2>Welcome!</h2>
          <p>Log in with your Email ID/Mobile number and password</p>
          <p id="loginErrorMsg"></p>
        </div>
        <div className="signInBody" >
          <input
            type="text"
            id="username"
            name="username"
            className="input-design my-2"
            placeholder="Email ID Or Mobile Number"
            onChange={this.handleInputChange}
          />
          <input
            type="password"
            id="loginpassword"
            name="loginpassword"
            className="input-design my-2"
            placeholder="Password"
            style={{ display: 'none' }}
            onChange={this.handleInputChange}
          />
          <div style={{ position: 'relative' }}>
            <input
              type="text"
              id="otp"
              name="otp"
              className="input-design my-2"
              placeholder="OTP"
              style={{ display: 'none' }}
              onChange={this.handleInputChange}
            />

            <span id="resendOTP" className="otpButton" style={{ display: 'none' }}>Resend OTP</span>
          </div>
          <div className="signInForgot">
            <a id="forgotPswd">Forgot password?</a>
          </div>
          <div className="signInButton">
            <button
              className="formButtonBg"
              id="Loginsubmit"
            //on={this.onSubmit}
            >
              Submit
            </button>
          </div>
          <div className="signInButton"><span className="formButtonBg" style={{ display: 'none' }} type="submit" id="PasswordLogin">Login</span></div>
          <div className="signInButton"><span className="formButtonBg" style={{ display: 'none' }} type="submit" id="OTPLogin">Login</span></div>
          <div className="signUp">
            <h3>
              Dont have an account? <a id="signUp">Sign up!</a>
            </h3>
          </div>
        </div>
      </div>
    );
  }
}
export default connect()(Login)
