// store.js
import { configureStore } from '@reduxjs/toolkit';
import authSlice from './reducers/authSlice'; // Assuming your reducer is named authSlice

const store = configureStore({
  reducer: {
    auth: authSlice,
  },
  // Add middleware if needed
});

export default store;
