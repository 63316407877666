import React, { useState, useEffect, Suspense, useRef } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Doctorinformation from "./doctorinformation";
import DoctorOffcicehours from "./doctorOffcicehours";
import DoctorSpecialisation from "./doctorSpecialisation";
import EducationDetails from "./educationDetails";
import Achievementsdetails from "./achievementsdetails";
import Proceduredetails from "./proceduredetails";
import OfficialInformation from "./officialInformation";
import SearchOffceService from "./searchoffceservice";
import Doctorseo from "./doctorseo";
import DoctorPhoto from "./doctorPhoto";
import { apiCalling } from "../../apiService";
import $ from "jquery";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Header from "../../layout/header";
import Footer from "../../layout/footer";

const folderName = window.localStorage.getItem("homeName");
var isVerifiedFlag = true;
var isVerifiedValue;
const AddnewHsp = () => {
  const location = useLocation();
  const specialisationElementRef = useRef(null);
  const educationElementsRef = useRef(null);
  const achievementsElementsRef = useRef(null);
  const procedureElementsRef = useRef(null);
  const officeHoursElementsRef = useRef(null);
  const officeServiceElementsRef = useRef(null);
  const doctorInformationElementRef = useRef(null);
  const officialInformationElementRef = useRef(null);
  const doctorPhotoElementRef = useRef(null);
  const [paidFromDate, setPaidFromDate] = useState(null);
  const [paidToDate, setPaidToDate] = useState(null);
  const [state, setState] = useState({
    languageData: [],
    timeFilterData: [],
    getDropdownLists: [],
    getHospitalDropdown: [],
    dateOfBirth: "",
    doctorId: "",
    doctorInformationArray: [],
    doctorDataArray: [],
    educationData: [],
    achievementsData: [],
    proceduresData: [],
    doctorOfficeHours: [],
    isData: 0,
    languageEdit
      : [],
    associations: [],
    specialisationEdit: [],
    statementProf: "",
    specilizationData: [],
  });
  const [isMounted, setIsMounted] = useState(false);
  const [operationsCompleted, setOperationsCompleted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
    const fetchData = async () => {
      try {
        const [hospitalData, dropdownLists] = await Promise.all([
          dataLoad(),
          listing(),
        ]);
        if (isMounted && (!location || !location.state)) {
          setState((prevState) => ({
            ...prevState,
            getHospitalDropdown: hospitalData,
            getDropdownLists: dropdownLists,
          }));
          setOperationsCompleted(true);
          $(".loader").hide();
        } else {
          setState((prevState) => ({
            ...prevState,
            getHospitalDropdown: hospitalData,
            getDropdownLists: dropdownLists,
            isData: 1,
          }));
          setOperationsCompleted(true);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
    return () => {
      setIsMounted(false);
    };
  }, [isMounted]);
  useEffect(() => {
    if (operationsCompleted && location && location.state) {
      const doctorId = location.state;
      setState((prevState) => ({
        ...prevState,
        doctorId: doctorId,
      }));
      editDoctorData(doctorId);
      getDoctorSpecialization(doctorId);
    }
    $(".loader").hide();
  }, [operationsCompleted, location]);
  const dataLoad = async () => {
    $(".loader").show();
    const apiJson = {
      method: 'POST',
      body: JSON.stringify({
        functionName: 'getHospitalDropdown',
      }),
    };
    const response = await apiCalling(apiJson);
    if (response.success === '1') {
      return response.hospitalData[0];
    }
    return null;
  }
  const listing = async () => {
    const apiJson = {
      method: 'POST',
      body: JSON.stringify({
        functionName: 'getDropdownLists',
      }),
    };
    const response = await apiCalling(apiJson);
    if (response.success === '1') {
      return response.result[0];
    }
    return null;
  }
  useEffect(() => {
    if (operationsCompleted && location && location.state) {
      setState((prevState) => ({
        ...prevState,
        doctorId: location.state,
      }));
      editDoctorData(location.state);
      getDoctorSpecialization(location.state);
    }

  }, [operationsCompleted, location]);
  const editDoctorData = async (doctorId) => {
    window.scroll(0, 0);
    $(".loader").show();
    $("#btnSave").hide();
    $("#btnNewSave").hide();
    $("#btnUpdate").show();
    $("#btnNewUpdate").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "editDoctorDetails",
        doctorId: doctorId,
      }),
    };
    apiCalling(apiJson).then((data) => {
      console.log(data,"Pppppppppppppppppppppppppppppppppp");
      $(".loader").hide();
      var doctorOfficeHrs = [];
      var schedule = [];
      let doctorScheduleData =
        data.doctorScheduleData === undefined ? [] : data.doctorScheduleData;
      let languages = data.languages === undefined ? [] : data.languages;
      let associationData =
        data.associationData === undefined ? [] : data.associationData;
      doctorScheduleData.forEach((item, i) => {
        var s = {
          sundayTo: item.sundayTo,
          sundayFrom: item.sundayFrom,
          mondayTo: item.mondayTo,
          mondayFrom: item.mondayFrom,
          tuesdayTo: item.tuesdayTo,
          tuesdayFrom: item.tuesdayFrom,
          wednesdayTo: item.wednesdayTo,
          wednesdayFrom: item.wednesdayFrom,
          thursdayTo: item.thursdayTo,
          thursdayFrom: item.thursdayFrom,
          fridayTo: item.fridayTo,
          fridayFrom: item.fridayFrom,
          saturdayTo: item.saturdayTo,
          saturdayFrom: item.saturdayFrom,
        };
        schedule.push(s);
        const hospitalData = data.doctorData[0].hospitalData.filter(
          (o) => o.hospitalId === item.hospitalId
        );
        if (i % 2 === 1) {
          doctorOfficeHrs.push({
            hospitalId: item.hospitalId,
            hasOwner: hospitalData[0].hasOwner,
            schedule: schedule,
          });
          schedule = [];
        }
      })
      let languageEdit = [];
      for (let i = 0; i < languages.length; i++) {
        languageEdit.push(languages[i].Id);
      }
      let associationEdit = [];
      for (let i = 0; i < associationData.length; i++) {
        associationEdit.push(associationData[i].associationId);
      }
      if (data.success === "1") {
        console.log('dddddddddd',data);
        setState((prevState) => ({
          ...prevState,
          languageEdit: languageEdit,
          associations: associationEdit,
          doctorOfficeHours: doctorOfficeHrs,
          doctorDataArray: data.doctorData === undefined ? [] : data.doctorData,
          educationData: data.education === undefined ? [] : data.education,
          achievementsData:
            data.achievements === undefined ? [] : data.achievements,
          proceduresData: data.procedures === undefined ? [] : data.procedures,
          specialisationEdit:
            data.specialization === undefined ? [] : data.specialization,
          doctorId:
            data.doctorData === undefined ? "" : data.doctorData[0].doctorId,
        }));
        let dta = data.doctorData;
        if (dta.length > 0) {
         
         
          window.localStorage.setItem("paidToDate", dta[0].paidToDate);
          window.localStorage.setItem("paidFromDate", dta[0].paidFromDate);

          window.localStorage.setItem("seoDoctorId", dta[0].doctorId);
          window.localStorage.setItem("seoTitle", dta[0].doctorTitle);
          window.localStorage.setItem(
            "seoDisplaytName",
            dta[0].doctorFirstName + dta[0].doctorLastName
          );
          window.localStorage.setItem("seoCity", dta[0].city);
          window.localStorage.setItem("seoState", dta[0].state);
          window.localStorage.setItem("seoSubcity", dta[0].subCity);
        }
      }
      setState((prevState) => ({
        ...prevState,
        isData: 1,
      }));
    });
  };
  const getDoctorSpecialization = (doctorId) => {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getDoctorSpeciality",
        doctorId: doctorId,
      }),
    };
    apiCalling(apiJson).then((data) => {
      setState((prevState) => ({
        ...prevState,
        specilizationData: data.resultData,
      }));
      let SpecializationData = "";
      for (let i = 0; i < data.resultData?.length; i++) {
        if (SpecializationData === "") {
          SpecializationData = data.resultData[i].specializationName;
        } else {
          SpecializationData =
            SpecializationData + "|" + data.resultData[i].specializationName;
        }
      }
      window.localStorage.setItem("seoSpecialization", SpecializationData);
    });
  };
  const isEmail = (email) => {
    var isMailOk = new RegExp(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,6}$/i).test(
      email
    );
    return isMailOk;
  };
  const videoOk = () => {
    $(".videocall_div").attr("hidden", true);
  };
  const saveDoctor = () => {
    let officeHoursArray = []
    if (officeHoursElementsRef.current) {
      officeHoursArray = officeHoursElementsRef.current.OfficeHours();
    }
    const educations = educationElementsRef.current.education();
    const specialisations = specialisationElementRef.current.specialisations();
    const achievementsArray = achievementsElementsRef.current.achievements();
    const procedureArray = procedureElementsRef.current.procedures();
    const servicesArray = officeServiceElementsRef.current.searchOfficeServices();
    const doctorInformationImage = doctorInformationElementRef.current.doctorinformation();
    const officialInfoDrImage = officialInformationElementRef.current.officialInformationData();
    let doctorPhoto = "";
    if (state.doctorId !== "") {
      doctorPhoto = doctorPhotoElementRef.current.doctorPhoto();
    }
    const phonePattern = /^[0-9]{10}$/;
    let flag = true;
    const email = $("#txtEmail").val();

    if ($("#txtFirstName").val() === "") {
      alert("First Name Required");
      setTimeout(() => {
        $("#txtFirstName").focus().mousedown();
      }, 10);
      flag = false;
    } else if ($("#cmbTitle").val() === "") {
      alert("Title Required");
      setTimeout(() => {
        $("#cmbTitle").focus().mousedown();
      }, 10);
      flag = false;
    } else if (
      !$("#rdoMale").is(":checked") &&
      !$("#rdoFemale").is(":checked")
    ) {
      alert("Gender Required");
      setTimeout(() => {
        $("#rdoMale").focus().mousedown();
      }, 10);
      flag = false;
    } else if ($("#cmbLanguage").val().length === 0) {
      alert("Select Languages");
      setTimeout(() => {
        $("#cmbLanguage").focus().mousedown();
      }, 10);
      flag = false;
    } else if ($("#cmbWorkTimeFilter").val().length === 0) {
      alert("Select Work Time");
      setTimeout(() => {
        $("#cmbWorkTimeFilter").focus().mousedown();
      }, 10);
      flag = false;
    }
    // else if (officeHoursArray.DoctorOfficeHours.length === 0) {
    //   alert("Click Plus Button To Add Hospital Names");
    //   $("#cmbHospitalName").focus();
    //   flag = false;
    // } 
    else if ($("#txtEmail").val() === "") {
      alert("Doctor Email Required");
      setTimeout(() => {
        $("#txtEmail").focus().mousedown();
      }, 10);
      flag = false;
    } else if (!isEmail(email)) {
      alert("Invalid Email id");
      setTimeout(() => {
        $("#txtEmail").focus().mousedown();
      }, 10);
      flag = false;
    } else if ($("#txtAddress").val() === "") {
      alert("Address Required");
      setTimeout(() => {
        $("#txtAddress").focus().mousedown();
      }, 10);
      flag = false;
    }else if ($("#cmbCountry").val() === "0") {
      alert("Country Required");
      setTimeout(() => {
        $("#cmbCountry").focus().mousedown();
      }, 10);
      flag = false;
    }  
    else if ($("#cmbState").val() === "0") {
      alert("State Required");
      setTimeout(() => {
        $("#cmbState").focus().mousedown();
      }, 10);
      flag = false;
    } else if ($("#cmbCity").val() === "0" || $("#cmbCity").val() === null) {
      alert("City Required");
      setTimeout(() => {
        $("#cmbCity").focus().mousedown();
      }, 10);
      flag = false;
    } else if ($("#txtPinCode").val() === "") {
      alert("Pin Code Required");
      setTimeout(() => {
        $("#txtPinCode").focus().mousedown();
      }, 10);
      flag = false;
    } else if ($("#txtMobile").val() === "") {
      alert("Doctor Mobile Number Required");
      setTimeout(() => {
        $("#txtMobile").focus().mousedown();
      }, 10);
      flag = false;
    } else if (!phonePattern.test($("#txtMobile").val())) {
      alert("Doctor Mobile Number must be 10 digits");
      setTimeout(() => {
        $("#txtMobile").focus().mousedown();
      }, 10);
      flag = false;
    } else if ($("#cmbMedicineBranch").val() === "") {
      alert("Medicine Branch Name Required");
      setTimeout(() => {
        $("#cmbMedicineBranch").focus().mousedown();
      }, 10);
      flag = false;
    }
    else if (!specialisations?.SpecialisationArray?.length > 0) {
      alert("Specialisations Required");
      setTimeout(() => {
        $("#cmbMedicineBranch").focus().mousedown();
      }, 10);
      flag = false;
    } else if ($("#txtProfessionalStatement").val() === "") {
      alert("Brief Description about yourself Required");
      setTimeout(() => {
        $("#txtProfessionalStatement").focus().mousedown();
      }, 10);
      flag = false;
    }
    else if (educations.EducationDetails.length === 0) {
      alert("Education Details Required");
      setTimeout(() => {
        $("#cmbEducation").focus().mousedown();
      }, 10);
      flag = false;
    } else if (officeHoursArray.DoctorOfficeHours.length === 0) {
      alert("Hospital Details Required");
      setTimeout(() => {
        $(".doctorhspwrkdetal-ofcehour").focus().mousedown();
      }, 10);
      flag = false;
    }
    const specializations = specialisations?.SpecialisationArray;
    if (specializations.length > 0 && flag) {
      let isPrimary = false;
      let multiple = 0;

      specializations.forEach(specialization => {
        const isPrimaryChecked = specialization.isPrimarySpecialisation === "true";
        if (isPrimaryChecked) {
          isPrimary = true;
          multiple += 1;
        }
      });
      if (!isPrimary && specializations.length > 0) {
        alert("At least one Primary Specialisation Required");
        flag = false;
      } else if (multiple > 1) {
        alert("Only one Primary Specialisation Required");
        flag = false;
      }
    }

    if (flag) {
      const paidToDate = $("#paidToDate").val();
      const paidFromDate = $("#paidFromDate").val();
      var isChecked = $('#chkAcceptMobileAppOnly').prop('checked');
      const hospitalDoctorDetails = officeHoursArray.DoctorOfficeHours
        .filter((detail) => detail.hospitalId !== "")
        .map((detail) => ({
          hospitalId: detail.hospitalId,
          hasOwner: detail.hasOwner,
        }));

      // const specilisationArray = specializations
      //   .filter((specialization) =>
      //     $("#chkSpecialisation" + specialization.specialityId).is(":checked")
      //   )
      //   .map((specialization) => ({
      //     specializationIds: $("#chkSpecialisation" + specialization.specialityId).val(),
      //     isPrimarySpecialisation: $("#chkPrimarySpecialisation" + specialization.specialityId).is(":checked")
      //       ? "true"
      //       : "false",
      //   }));
      // console.log( specilisationArray,"specialisationsspecialisationsspecialisationsspecialisationsspecialisations");

      const educationArray = educations.EducationDetails
        .filter((education) => education.Id !== "")
        .map((education) => ({
          educationId: education.Id,
          year: education.year,
          college: education.college.toString().replaceAll(/'/g, "''"),
        }));

      const achievementDetailsArray = achievementsArray.AchievementArray

        .filter((achievement) => achievement.Id !== "")
        .map((achievement) => ({
          achievementId: achievement.Id,
          description: achievement.description,
        }));

      const procedureDetails = procedureArray.procedureDetail
        .filter((procedure) => procedure.Id !== "")
        .map((procedure) => ({
          procedureId: procedure.Id,
          minPrice: procedure.minPrice,
          maxPrice: procedure.maxPrice,
          buyPrice: procedure.buyPrice,
          mtMinPrice: procedure.mtMinPrice,
          mtMaxPrice: procedure.mtMaxPrice,
          mtBuyPrice: procedure.mtBuyPrice,
          isActive: procedure.isActive,
        }));

      const associationId = state.associations.toString().replace(/[\[\]']/g, "");
      if (associationId === "") {
        alert("Association(s) required for the doctor");
        return;
      }

      if (paidToDate.length > 0 && paidFromDate.length == 0) {
        alert("Please select paid from date before paid to date");
        return;
      }
      if (isChecked === false && paidFromDate.length == 0) {
        alert("Please select paid from date");
        return;
      }
      if (isChecked === false && paidToDate.length == 0) {
        alert("Please select paid to date");
        return;
      }
      if (isChecked === false && paidFromDate.length > 0 && paidToDate.length > 0) {
        if (paidFromDate !== '00/00/00' && paidToDate !== '00/00/0000') {
          var parts = paidFromDate.split("/");
          var day = parseInt(parts[0], 10);
          var month = parseInt(parts[1], 10) - 1; // Months are zero-based (January is 0)
          var year = parseInt(parts[2], 10);
          var date_f = year + '-' + month + "-" + day;

          var paidFromDate_ob = new Date(date_f);

          var parts1 = paidToDate.split("/");
          var day1 = parseInt(parts1[0], 10);
          var month1 = parseInt(parts1[1], 10) - 1; // Months are zero-based (January is 0)
          var year1 = parseInt(parts1[2], 10);
          var date_t = year1 + '-' + month1 + "-" + day1;

          var paidToDate_ob = new Date(date_t);
          if (paidFromDate_ob > paidToDate_ob) {
            alert("paid From Date should be less than paid To Date");
            return;
          }
        }
      }
      const timeFilter = $("#cmbWorkTimeFilter")
        .val()
        .toString()
        .replace(/[\[\]']/g, "");

      const languageIds = $("#cmbLanguage")
        .val()
        .toString()
        .replace(/[\[\]']/g, "");

      const seoDescription = $("#txtDescriptionNew").val() === ""
        ? ""
        : $("#txtDescriptionNew").val().replace(/'/g, "''");

      if (seoDescription.length > 1000) {
        alert("Seo Description must not exceed 1000 characters");
        return false;
      }

      const activeDate = $("#activeDate").val();
      const deactiveDate = $("#deactiveDate").val();
      if (deactiveDate < activeDate) {
        alert("Deactive Date must be greater than Active Date");
        $("#deactiveDate").focus();
        return false;
      }

      if (educationArray.length < 1) {
        alert("Atleast One Education Required");
        $("#cmbEducation").get(0).focus();
        return false;
      }

      const searchKeywords = $("#txtAreaSearchKeyword").val().replace(/'/g, "''");
      if (searchKeywords.length > 1000) {
        alert("Seo Keywords must not exceed 1000 characters");
        return false;
      }

      const apiJson = {
        method: "POST",
        body: JSON.stringify({
          functionName: "saveDoctor",
          activeDate: $("#activeDate").val(),
          address: $("#txtAddress").val(),
          advertisementId: $("#txtAdvertisementId").val(),
          city: $("#cmbCity option:selected").text(),
          country: $("#cmbCountry option:selected").text(),
          createdBy: $("#txtCreatedBy").val(),
          createdOn: $("#txtCreatedOn").val(),
          dateOfBirth: $("#txtDateOfBirth").val(),
          deactiveDate: $("#deactiveDate").val(),
          doctorFirstName: $("#txtFirstName").val(),
          doctorLastName: $("#txtLastName").val(),
          gender: $("input:radio[name=rdoGenderType]:checked").val(),
          isAcceptOnline: $("#chkAcceptOnlineAppointment").is(":checked")
            ? "true"
            : "false",
          isAssistedEnabled: $("#cmbIsAssisted").val(),
          isBackgroundVerified: false,
          hasSaveHospital: false,
          isEhrEnabled: $("#cmbIsEHREnabled").val(),
          isMedicalTourism: $("#cmbMedTourism").val(),
          isVaasEnabled: $("#cmbIsVaasEnabled").val(),
          isVideoAccountCreated: false,
          isVideoEnabled: $("#cmbIsVideoorAudio").val(),
          keyWords: $("#txtKeyword").val() === "" ? "" : $("#txtKeyword").val(),
          medicalRegNumber: $("#txtMedRegistrationNo").val(),
          medicineBranchId: $("#cmbMedicineBranch").val(),
          mobileNumber: $("#txtMobile").val(),
          modifiedBy: $("#txtModifiedBy").val(),
          modifiedOn: $("#txtModifiedOn").val(),
          phone: $("#txtPhone").val(),
          practiceStartYear: $("#cmbPracticeStartYear").val(),
          description: seoDescription.replace(/'/g, "''"),
          professionalStatement: $("#txtProfessionalStatement")
            .val()
            .replace(/'/g, "''"),
          ratePerMinute: 0,
          ratePerSession: 0,
          rating: $("#txtRating").val(),
          regDate: $("#registrationDate").val(),
          paidFromDate: $("#paidFromDate").val(),
          paidToDate: $("#paidToDate").val(),
          languageIds: languageIds,
          // doctorDescription: seoDescription.replace(/'/g, "''"),
          registrationstate:
            $("#cmbRegistrationState").val() === "0"
              ? ""
              : $("#cmbRegistrationState option:selected").text(),
          routeId: "22",
          searchKeywords: searchKeywords,
          seoTitle: $("#txtTitle").val() === "" ? "" : $("#txtTitle").val(),
          slug: $("#txtSlug").val() === "" ? "" : $("#txtSlug").val(),
          sortOrder: $("#txtSortOrder").val(),
          state: $("#cmbState option:selected").text(),
          subCity:
            $("#cmbSubCity").val() === "0"
              ? ""
              : $("#cmbSubCity option:selected").text(),
          timeFilter: timeFilter,
          doctorTitle: $("#cmbTitle").val(),
          doctorSignature: doctorInformationImage,
          doctorEmail: $("#txtEmail").val().trim(),
          associationId: associationId,
          doctorImage: officialInfoDrImage,
          doctorPhoto: doctorPhoto,
          zip: $("#txtPinCode").val(),
          isExcludeFromPh: false,
          hasInitialEvaluation: false,
          isAgreeTermsAndCondition: false,
          fromNetwork: false,
          networkServiceRequest: null,
          pmPaymentDone:"true",//as per the request from backend 20/06/2024 changing the flag to true before it was "false" by srinath
          mobileAppOnly: $("#chkAcceptMobileAppOnly").is(":checked")
            ? "true"
            : "false",
          lastFeedViewedTime: "",
          lastalertViewedTime: "",
          fromDoctorRegistration: state.doctorDataArray.length > 0
            ? state.doctorDataArray[0].fromDoctorRegistration
            : false,
          password: "",
          username: $("#txtEmail").val().trim(),
          version: 0,
          aqMakePublic: false,
          aqNumFollowUp: $("#cmbAQNumFollowUp").val(),
          aqPrice: $("#cmbAQPrice").val(),
          askQuestion: $("#cmbAskQuestion").val(),
          freeAqAvailCount: $("#txtFreeAQAvailableCount").val(),
          isFreeAq: $("#cmbIsFreeAQ").val(),
          isPhoneConsultAvail: $("#cmbPhoneConsulting").val(),
          officeDetails: servicesArray.content1.replace(/'/g, "''"),
          phoneConsultPrice: $("#txtPhoneConsultingPrice").val(),
          serviceOffered: servicesArray.content2,
          college: "",
          year: "",
          doctorId: $("#txtDoctorId").val(),
          consultationPrice: $("#txtInPersonConsultationPrice").val(),
          ispmmodeWrite: false,
          pmCalenderMode: "",
          hasInPersonAppointment: $("#cmbInPersonAppoinment").val(),
          isExcludeFromPh: $("#cmbExcludeFromPH").val(),
          hospitalId: $("#txtHospitalIdNew").val(),
          hospitalDoctorDetails: hospitalDoctorDetails,
          doctorOfficeHours: officeHoursArray.DoctorOfficeHours,
          isAccountLocked: false,
          isVerified: $("#cmbIsVerified").val(),
          status: $("#cmbNewStatus").val(),
          specializationIds: specializations,
          educationIds: educationArray,
          achievementIds: achievementDetailsArray,
          procedures: procedureDetails,
        }),
      };
      // console.log(apiJson.body, "apiJsonapiJsonapiJsonapiJsonapiJsonapiJsonapiJsonapiJson");
      $(".loader").show();
      setTimeout(() => {
        apiCalling(apiJson).then((data) => {
          if (data.success === "1") {
            $(".loader").hide();
            if ($("#txtDoctorId").val() === "") {
              alert("Doctor Details Saved Successfully");
            } else {
              alert("Doctor Details Updated Successfully");
            }
            $("#txtCancel").click();
            if (isVerifiedValue === "true" && isVerifiedFlag === false) {
              const json = {
                method: "POST",
                body: JSON.stringify({
                  functionName: "updateDoctorHospitalVerificationStatus",
                  updateType: "Doctor",
                  id: state.doctorId,
                  isVerified: true,
                }),
              };
              apiCalling(json).then((data) => {
                isVerifiedFlag = true;
              });
            }
          } else {
            $(".loader").hide();
            alert(data.errorMessage);
          }
        });
      }, 10000);
    }
  };


  const doctorDetails = () => {
    $("#doctorDetailsDiv").show();
    $("#seoDetailsDiv").hide();
    $("#doctorPhotoDiv").hide();
    $("#doctorDetails").addClass("active");
    $("#seoDetails").removeClass("active");
    $("#photoDetails").removeClass("active");
  };

  const seoDetails = () => {
    $("#doctorDetailsDiv").hide();
    $("#seoDetailsDiv").show();
    $("#doctorPhotoDiv").hide();
    $("#seoDetails").addClass("active");
    $("#photoDetails").removeClass("active");
    $("#doctorDetails").removeClass("active");
  };

  const photoDetails = () => {
    $("#doctorDetailsDiv").hide();
    $("#seoDetailsDiv").hide();
    $("#doctorPhotoDiv").show();
    $("#photoDetails").addClass("active");
    $("#seoDetails").removeClass("active");
    $("#doctorDetails").removeClass("active");
  };

  const proffesionalStatementSetting = (statement1) => {
    var statement = statement1;
    setState((prevState) => ({
      ...prevState,
      statementProf: statement,
    }));
  };

  const isVerifiedChange = (flag) => {
    isVerifiedFlag = flag;
    isVerifiedValue = $("#cmbIsVerified").val();
  };

  return (
    <>
      <div className="videocall_div" hidden id="shedulePopup">
        <div className="videocall_header">Message</div>
        <div className="videocall_body">Your chat has been ended</div>
        <div className="videocall_footer">
          <button
            type="button"
            className="bttn_ok"
            onClick={() => videoOk()}
          >
            Ok
          </button>
        </div>
      </div>
      <div className="bg-clr-wthHead">
        <Suspense fallback={<div></div>}>
          <section>
            <Header /> <div className="loader"></div>
          </section>
        </Suspense>
        <div className="container mb-5">
          <div>
            <ul className="breadcrum-adminMangmnt">
              <li>
                <a href="/admin/dashboardAdmin">Dashboard</a>
              </li>
              <li>
                <a href="/adminManagement/doctorManagementList/doctorManagement">
                  Doctor Management
                </a>
              </li>
              <li>
                <a href="" className="current">
                  Doctor Details
                </a>
              </li>
            </ul>
          </div>
          <div className="smssetng-btn mb-4 mt-4">
            <input
              type="submit"
              value="Save"
              id="btnNewSave"
              className="smssetng-svebtn"
              onClick={() => saveDoctor()}
            />
            <input
              type="submit"
              style={{ display: "none" }}
              id="btnNewUpdate"
              value="Update"
              className="smssetng-svebtn"
              onClick={() => saveDoctor()}
            />
            <Link
              to={{
                pathname:
                  "/adminManagement/doctorManagementList/doctorManagement",
              }}
            >
              <input
                type="submit"
                id="txtCancel"
                value="Cancel"
                className="smssetng-cancelbtn"
              />
            </Link>
          </div>
          <div className="addHspPage-Wrapper">
            <div className=" ">
              <div className="ph_drmngmnt ">
                <button
                  id="doctorDetails"
                  className="active"
                  onClick={() => doctorDetails()}
                >
                  Doctor Details
                </button>
                <button id="seoDetails" onClick={() => seoDetails()}>
                  {" "}
                  Seo
                </button>
                {state.doctorId !== "" ? (
                  <button
                    id="photoDetails"
                    onClick={() => photoDetails()}
                  >
                    Photo
                  </button>
                ) : (
                  ""
                )}
              </div>
              <div className="doctr-wraper-border" id="doctorDetailsDiv">
                {state.isData === 1 ? (
                  <Doctorinformation
                    ref={doctorInformationElementRef}
                    getDropdownLists={state.getDropdownLists}
                    getHospitalDropdown={state.getHospitalDropdown}
                    doctorId={state.doctorId}
                    doctorData={state.doctorDataArray}
                    languageEdit={state.languageEdit}
                  />
                ) : (
                  ""
                )}
                {state.isData === 1 ? (
                  <DoctorOffcicehours
                    ref={officeHoursElementsRef}
                    getDropdownLists={state.getDropdownLists}
                    getHospitalDropdown={state.getHospitalDropdown}
                    doctorOfficeHours={state.doctorOfficeHours}
                    doctorData={state.doctorDataArray}
                  />
                ) : (
                  ""
                )}
                {state.isData === 1 ? (
                  <DoctorSpecialisation
                    ref={specialisationElementRef}
                    getDropdownLists={state.getDropdownLists}
                    specialisationEdit={state.specialisationEdit}
                    doctorData={state.doctorDataArray}
                    proffesionalStatementSetting={
                      proffesionalStatementSetting
                    }
                  />
                ) : (
                  ""
                )}
                {state.isData === 1 ? (
                  <EducationDetails
                    getHospitalDropdown={state.getHospitalDropdown}
                    ref={educationElementsRef}
                    educationData={state.educationData}
                  />
                ) : (
                  ""
                )}
                {state.isData === 1 ? (
                  <Achievementsdetails
                    getDropdownLists={state.getDropdownLists}
                    ref={achievementsElementsRef}
                    achievementsData={state.achievementsData}
                  />
                ) : (
                  ""
                )}
                {state.isData === 1 ? (
                  <Proceduredetails
                    getHospitalDropdown={state.getHospitalDropdown}
                    ref={procedureElementsRef}
                    proceduresData={state.proceduresData}
                  />
                ) : (
                  ""
                )}
                {/* {console.log("state.doctorDataArray", state.associations)} */}
                {state.isData === 1 ? (
                  <OfficialInformation
                    getDropdownLists={state.getDropdownLists}
                    getHospitalDropdown={state.getHospitalDropdown}
                    ref={officialInformationElementRef}
                    doctorData={state.doctorDataArray}
                    associationEdit={state.associations}
                    setAssociations={(associations) => {
                      setState((prevState) => ({
                        ...prevState,
                        associations: associations,
                      }));
                    }}
                    isVerifiedChange={isVerifiedChange}
                  />
                ) : (
                  ""
                )}
                {state.isData === 1 ? (
                  <SearchOffceService
                    ref={officeServiceElementsRef}
                    statement={state.statementProf}
                    doctorData={state.doctorDataArray}
                  />
                ) : (
                  ""
                )}
                <div className="smssetng-btn text-left pt-2">
                  <input
                    type="submit"
                    value="Save"
                    id="btnSave"
                    className="smssetng-svebtn"
                    onClick={() => saveDoctor()}
                  />
                  <input
                    type="submit"
                    style={{ display: "none" }}
                    id="btnUpdate"
                    value="Update"
                    className="smssetng-svebtn"
                    onClick={() => saveDoctor()}
                  />
                  <Link
                    to={{
                      pathname:
                        "/adminManagement/doctorManagementList/doctorManagement",
                    }}
                  >
                    <input
                      type="submit"
                      value="Cancel"
                      id="txtCancel"
                      className="smssetng-cancelbtn"
                    />
                  </Link>
                </div>
              </div>
              <div
                className=" "
                id="seoDetailsDiv"
                style={{ display: "none" }}
              >
                {state.isData === 1 ? (
                  <Doctorseo doctorData={state.doctorDataArray} />
                ) : (
                  ""
                )}
              </div>
              {state.doctorId !== "" ? (
                <div
                  className=" "
                  id="doctorPhotoDiv"
                  style={{ display: "none" }}
                >
                  {state.isData === 1 ? (
                    <DoctorPhoto
                      ref={doctorPhotoElementRef}
                      doctorData={state.doctorDataArray}
                    />
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    </>
  );
};

export default AddnewHsp;
