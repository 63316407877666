import React, { Component, Suspense } from "react";
import deactvate from "../../image/icons/deactvate.png";
import plus from "../../image/icons/plus.png";
import { apiCalling } from "../../apiService";
import $ from "jquery";
import tick from "../../image/icons/tick.png";
import Pagination from "react-js-pagination";
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import Close from "../../image/icons/close-mark.png";
import moment from "moment";
import { includes } from "lodash";
let timeSlotArray = [];
var result = [];
let scheduledData = [];
let doctorSpecialityData = [];
let isValid = true;
const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
for (var i = 0; i < 7; i++) {
  var d = new Date();
  d.setDate(d.getDate() + i);
  result.push({
    dateMilli: d.getTime(),
    dateFull: moment(d).format("YYYY-MM-DD"),
    date: moment(d).format("DD-MMM-YYYY"),
    day: weekday[d.getDay()],
  });
  result.join(",");
}
// const hospitalName=window.localStorage.getItem("hospitalName")    
const hospitalId=window.localStorage.getItem("dataPassing")?JSON.parse(window.localStorage.getItem("dataPassing")):""
export default class Qualification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hospitalId: "",
      specialityList: [],
      totalCount: 0,
      activePage: 1,
      showMessage: "Waiting for data... ",
      appType: "",
      appTypeId: "",
      doctorName: "",
      doctorSpeciality: "",
      HoursValue: "select",
      Hours: "",
      HoursData: [],
      Minute: "",
      MinuteData: [],
      AmPm: "",
      AmPmData: [],
      ToHours: "",
      ToMinute: "",
      ToAmPm: "",
      doctorScheduleData: [],
      notExistsDoctorData: [],
      doctorScheduleDataNew: [],
      addNewFlag: false,
      scheduleDataOld: [],
      addFlag: [],
      addNewDiv: [],
      docSpeciality: "",
      doctorSpecialityData: [],
      isChangeVisible: false,
      VideoAdd: true,
      AudioAdd: true,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleVideoChange = this.handleVideoChange.bind(this);
    this.handleAudioChange = this.handleAudioChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.handleCheckChange = this.handleCheckChange.bind(this);
    this.scheduleEdit = this.scheduleEdit.bind(this);
    this.submitSchedule = this.submitSchedule.bind(this);
    this.deleteTimeSchedule = this.deleteTimeSchedule.bind(this);
    this.submitNewSchedule = this.submitNewSchedule.bind(this);
    this.handleVideoAdd = this.handleVideoAdd.bind(this);
    this.handleAudioAdd = this.handleAudioAdd.bind(this);
  }

  componentDidMount() {
    this.getHospitalSpecialityList();
  }

  getHospitalSpecialityList() {
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getHospitalSpecialityList",
        hospitalId:hospitalId,
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {

        $(".loader").hide();
        let notExistsDoctorData = [];
        if (data.result.notExistsDoctorData.length > 0) {
          notExistsDoctorData = data.result.notExistsDoctorData.map((item) => ({
            label: item.doctorName,
            value: item.doctorId,
            speciality: item.doctorSpeciality,
          }));
        }
        this.setState({
          specialityList: data.result.doctorScheduleData,
          notExistsDoctorData: notExistsDoctorData,
          totalCount: data.result.doctorScheduleData.length,
        });
      } else {
        $(".loader").hide();
        this.setState({
          specialityList: [],
          showMessage: data.errorMessage,
        });
      }
    });
  }
  // handleInputChange = (event, data, index) => {
  //   this.specialityList[index].ratePerSession=event.target.value

  //   this.setState({
  //     isChangeVisible: true,
  //   });
  // };
  handleInputChange = (event, data, index) => {
    const updatedSpecialityList = [...this.state.specialityList];
    updatedSpecialityList[index].ratePerSession = event.target.value;
    this.setState({
      specialityList: updatedSpecialityList,
      isChangeVisible: true,
    });
  };
  handleVideoChange = (event, data, index) => {
    this.setState({
      isChangeVisible: true,
    });
    $("#ratePerSession" + index).prop(
      "disabled",
      $("#videoType" + index).prop("checked") == true ? false : true
    );
  };
  handleAudioChange = (event, data, index) => {
    this.setState({
      isChangeVisible: true,
    });
    $("#ratePerAudioSession" + index).prop(
      "disabled",
      $("#audioType" + index).prop("checked") == true ? false : true
    );
  };
  handleVideoAdd = () => {
    this.setState({
      VideoAdd: $("#videoType").prop("checked") == true ? false : true,
    });
  };
  handleAudioAdd = () => {
    this.setState({
      AudioAdd: $("#audioType").prop("checked") == true ? false : true,
    });
  };
  scheduleValidation = () => {
    var regex = /^[0-9]+$/;
    let doctorSpecialityArrayNew = [];
    let specialityList = this.state.specialityList;
    for (let i = 0; i < specialityList.length; i++) {
      let ratePerSession = $("#ratePerSession" + i).val() ? $("#ratePerSession" + i).val().replace(/[^a-zA-Z0-9.]/g, '') : "";
      let videoType = $("#videoType" + i).prop("checked") == true ? 1 : 0;
      let averageSessionTime = $("#averageSessionTime" + i).val();
      let audioType = $("#audioType" + i).prop("checked") == true ? 1 : 0;
      let ratePerAudioSession = $("#ratePerAudioSession" + i).val();
      if (
        ratePerSession !== "" &&
        !parseFloat(ratePerSession).toFixed(0).match(regex)
      ) {
        alert("Please enter valid amount");
        //$("#ratePerSession"+i).focus()
        isValid = false;
        break;
      } else if (
        averageSessionTime !== "" &&
        !parseFloat(averageSessionTime).toFixed(0).match(regex)
      ) {
        alert("Please enter valid Enter valid average session time");
        //$("#averageSessionTime"+i).focus()
        isValid = false;
        break;
      } else if (
        ratePerAudioSession !== "" &&
        !parseFloat(ratePerAudioSession).toFixed(0).match(regex)
      ) {
        alert("Please enter valid amount");
        //$("#ratePerAudioSession"+i).focus()
        isValid = false;
        break;
      } else {
        isValid = true;
        doctorSpecialityArrayNew.push({
          doctorId: specialityList[i].doctorId,
          ratePerSession:
            ratePerSession == ""
              ? ratePerSession
              : parseFloat(ratePerSession).toFixed(2),
          averageSessionTime:
            averageSessionTime == ""
              ? averageSessionTime
              : parseFloat(averageSessionTime).toFixed(2),
          audioRatePerSession:
            ratePerAudioSession == ""
              ? ratePerAudioSession
              : parseFloat(ratePerAudioSession).toFixed(2),
          audioEnabled: audioType == true ? 1 : 0,
          videoEnabled: videoType == true ? 1 : 0,
          hospitalDoctorVideoScheduleId:
            specialityList[i].hospitalDoctorVideoScheduleId,
        });
      }
      doctorSpecialityData = doctorSpecialityArrayNew;
    }
  };
  submitNewSchedule = () => {
    this.scheduleValidation();
    if (isValid == true) {
      $(".loader").show();
      const apiJson = {
        method: "POST",
        body: JSON.stringify({
          functionName: "updateHospitalDoctorSchedule",
          hospitalId:hospitalId,
          drScheduleDetailsList: doctorSpecialityData,
        }),
      };
      apiCalling(apiJson).then((data) => {
        $(".loader").hide();
        if (data.success === "1") {
          // this.getHospitalSpecialityList();
          this.submitScheduleCancel()
        }
        if (data.success === "0") {
          $(".loader").hide();
          alert(data.errorMessage);
        }
      });
    }
  };
  submitScheduleCancel = () => {
    this.setState({
      specialityList: [],
      isChangeVisible: false
    });
    $("#popup1").hide();
    this.getHospitalSpecialityList()
  }
  addScheduleNew = () => {
    let doctorSpecialityArray = [];
    let ratePerSession = $("#ChargeSession").val() ? $("#ChargeSession").val().replace(/[^a-zA-Z0-9.]/g, '') : "";
    let videoType = $("#videoType").prop("checked") == true ? 1 : 0;
    let averageSessionTime = $("#averagePrice").val();
    let audioType = $("#audioType").prop("checked") == true ? 1 : 0;
    let ratePerAudioSession = $("#AudioChatPrice").val();
    let doctorId = $("#selectDoctor").val().split("_")[0];
    if (doctorId == null || doctorId == undefined || doctorId == 0) {
      alert("Please select doctor");
    } else {
      //$(".loader").show();
      doctorSpecialityArray.push({
        doctorId: doctorId,
        ratePerSession:
          ratePerSession == ""
            ? ratePerSession
            : parseFloat(ratePerSession).toFixed(2),
        // parseFloat(ratePerSession).toFixed(2),
        averageSessionTime:
          averageSessionTime == ""
            ? 15
            : parseFloat(averageSessionTime).toFixed(2),
        audioRatePerSession:
          ratePerAudioSession == ""
            ? ratePerAudioSession
            : parseFloat(ratePerAudioSession).toFixed(2),
        audioEnabled: audioType == true ? 1 : 0,
        videoEnabled: videoType == true ? 1 : 0,
        hospitalDoctorVideoScheduleId: "",
      });
      const apiJson = {
        method: "POST",
        body: JSON.stringify({
          functionName: "updateHospitalDoctorSchedule",
          hospitalId:hospitalId,
          drScheduleDetailsList: doctorSpecialityArray,
        }),
      };
      apiCalling(apiJson).then((data) => {
        if (data.success === "1") {
          $("#ChargeSession").val("");
          $("#videoType").prop("checked", false);
          $("#averagePrice").val("");
          $("#audioType").prop("checked", false);
          $("#AudioChatPrice").val("");
          $("#selectDoctor").val(0);
          this.setState({
            docSpeciality: "",
            AudioAdd: true,
            VideoAdd: true,
          });
          this.getHospitalSpecialityList();
        }
        if (data.success === "0") {
          $(".loader").hide();
          alert(data.errorMessage);
        }
      });
    }
  };

  doctorChange = (e) => {
    let speciality = e.target.value.split("_")[1];
    this.setState({
      docSpeciality: speciality,
    });
  };

  deleteSchedule = (id) => {
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "deleteHospitalDoctorVideoSchedule",
        hospitalDoctorVideoScheduleId: id,
        hospitalId:hospitalId,
        doctorId: this.state.specialityList[0].doctorId,
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        this.getHospitalSpecialityList();
      } else {
        $(".loader").hide();
        alert(data.errorMessage);
      }
    });
  };

  createSchedule(data) {
    $(".loader").show();
    $("#popup1").show();
    $(".popOverlay").css("background-color", "rgba(0, 0, 0, 0.6)");
    this.setState({
      doctorName: data.doctorName,
      doctorSpeciality: data.speciality,
    });
    scheduledData = data;
    this.editScheduleData(data);
  }
  closepopup() {
    $("#popup1").hide();
    $(".popOverlay").css("background-color", "transparent");
    timeSlotArray = [];
    scheduledData = [];
    this.setState({
      doctorScheduleDataNew: [],
    });
    $(".table-doctrworkListBorder .doctroffceInput").val("0");
    $(".table-doctrworkListBorder .doctroffceInputCheck").prop(
      "checked",
      false
    );
    this.setState({
      addNewDiv: [],
    });
    this.getHospitalSpecialityList();
  }

  handleSelectChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleCheckChange = (e, index, check) => {
    // this.dataPush(index)
  };
  scheduleEdit = (index, itemLength, date) => {
    let pagePreLength =
      $("#dataSet" + index).val() === "0" ? 1 : $("#dataSet" + index).val();
    //let pagePreLength = $('#dataSet'+index).val()
    let dataSetLength = parseInt(pagePreLength) + 1;
    let Hours = $(
      "#tableRow" + index + "_" + pagePreLength + " " + "#Hours" + index
    ).val();
    let Minute = $(
      "#tableRow" + index + "_" + pagePreLength + " " + "#Minute" + index
    ).val();
    let AmPm = $(
      "#tableRow" + index + "_" + pagePreLength + " " + "#AmPm" + index
    ).val();
    let ToHours = $(
      "#tableRow" + index + "_" + pagePreLength + " " + "#ToHours" + index
    ).val();
    let ToMinute = $(
      "#tableRow" + index + "_" + pagePreLength + " " + "#ToMinute" + index
    ).val();
    let ToAmPm = $(
      "#tableRow" + index + "_" + pagePreLength + " " + "#ToAmPm" + index
    ).val();
    let startTime = this.formatAMPM(date, Hours, Minute, AmPm);
    startTime = new Date(startTime).getTime();
    let endTime = this.formatAMPM(date, ToHours, ToMinute, ToAmPm);
    endTime = new Date(endTime).getTime();
    if (Hours == "0") {
      alert("Please select From Hour");
    } else if (Minute == "0") {
      alert("Please select From Minute");
    } else if (AmPm == "0") {
      alert("Please select From AM/PM");
    } else if (ToHours == "0") {
      alert("Please select From Hour");
    } else if (ToMinute == "0") {
      alert("Please select From Minute");
    } else if (ToAmPm == "0") {
      alert("Please select From AM/PM");
    } else if (startTime > endTime) {
      alert("Please enter correct To Time");
    } else {
      this.saveHandle();
      let validationFlag = true;
      let timeSlotArrayFilter = [];
      timeSlotArrayFilter = timeSlotArray
        .filter(
          (data) => date == moment(data.thresholdLimit).format("DD-MMM-YYYY")
        )
        .map((item) => item);
      for (let i = 0; i < timeSlotArrayFilter.length; i++) {
        let scheduleStartTime = timeSlotArrayFilter[i].scheduleStartTime;
        let scheduleEndTime = timeSlotArrayFilter[i].scheduleEndTime;
        if (
          validationFlag == true &&
          ((startTime > scheduleStartTime && startTime < scheduleEndTime) ||
            (endTime > scheduleStartTime && endTime < scheduleEndTime))
        ) {
          alert("Please enter correct To Time");
          validationFlag = false;
          break;
        } else if (
          validationFlag == true &&
          startTime < scheduleStartTime &&
          endTime > scheduleEndTime
        ) {
          alert("Please enter correct To Time");
          validationFlag = false;
          break;
        } else {
          validationFlag = true;
        }
      }
      if (validationFlag == true) {
        $("#dataSet" + index).val(dataSetLength);
        //alert('aaaaaaaaaaaaaa'+$('#dataSet'+index).val())
        let newArray = [...this.state.addNewDiv];
        let dataArray = [];
        dataArray.push(
          ...this.state.addNewDiv,
          (newArray[index] = [this.addNewTemplate(index, dataSetLength)])
        );
        this.setState({
          addNewDiv: dataArray,
        });
      }
    }
  };

  deleteTimeSchedule = (index, parentIndex, Name, item) => {
    console.log('itemmmmmm',item);
    if (Name !== "NewDelete") {
      // let limit = $('#dataSet'+index).val()
      // let indexOne = parseInt(index) + 1;
      var array = this.state.doctorScheduleDataNew;
      array[parentIndex].dataSet.splice(index, 1);
      this.setState({ doctorScheduleDataNew: array });
      const apiJson = {
        method: "POST",
        body: JSON.stringify({
          functionName: "deleteDoctorTimeSlot",
          doctorId: item.doctorId,
          hospitalId:item.hospitalId,
          fromTime:item.fromTime,
          toTime:item.toTime,
         // doctorVideoscheduleId: item.doctorVideoscheduleId,
        }),
      };
      apiCalling(apiJson).then((data) => {
        $(".loader").hide();
        if (data.success === "0") {
          alert(data.errorMessage);
        }
      });
    } else {
      if (parentIndex === 1) {
        // id={"ToAmPm"+index}id={"ToMinute"+index}id={"ToHours"+index}id={"AmPm"+index}id={"Minute"+index}id={"Hours"+index}
        $("#ToAmPm" + index + ' option[value="0"]').attr(
          "selected",
          "selected"
        );
        $("#ToMinute" + index + ' option[value="0"]').attr(
          "selected",
          "selected"
        );
        $("#ToHours" + index + ' option[value="0"]').attr(
          "selected",
          "selected"
        );
        $("#AmPm" + index + ' option[value="0"]').attr("selected", "selected");
        $("#Minute" + index + ' option[value="0"]').attr(
          "selected",
          "selected"
        );
        $("#Hours" + index + ' option[value="0"]').attr("selected", "selected");
        // $("#ToAmPm"+index+" select").text("0")
        // $("div.id_100 select").val("val2");
        // $('#categoryId option[value="2"]').attr("selected", true);
      } else {
        $("#tableRow" + index + "_" + parentIndex).remove();
        var array = timeSlotArray;
        let indexOne = parseInt(parentIndex) - 1;
        array.splice(indexOne, 1);
        timeSlotArray = array;
      }
    }
  };

  formatAMPM = (date, hours, minutes, ampm) => {
    var hours = parseInt(hours);
    var minutes = parseInt(minutes);
    var AMPM = ampm;
    var date = date;
    if (AMPM == "PM" && hours < 12) hours = hours + 12;
    if (AMPM == "AM" && hours == 12) hours = hours - 12;
    var sHours = hours.toString();
    var sMinutes = minutes.toString();
    if (hours < 10) sHours = "0" + sHours;
    if (minutes < 10) sMinutes = "0" + sMinutes;
    let time = sHours + ":" + sMinutes + ":" + "00";
    date = date + " " + time;
    return date;
  };
  saveHandle = () => {
    let timeSlotArrayOne = [];
    for (let i = 0; i < result.length; i++) {
      let index = i;
      let jmax =
        $("#dataSet" + index).val() === "0" ? 1 : $("#dataSet" + index).val();
      //let jmax = $('#dataSet'+index).val()
      for (let j = 1; j <= jmax; j++) {
        let fromHH = $(
          "#tableRow" + i + "_" + j + " " + "#Hours" + index
        ).val();
        let fromMM = $(
          "#tableRow" + i + "_" + j + " " + "#Minute" + index
        ).val();
        let FromAMPM = $(
          "#tableRow" + i + "_" + j + " " + "#AmPm" + index
        ).val();
        let ToHH = $(
          "#tableRow" + i + "_" + j + " " + "#ToHours" + index
        ).val();
        let ToMM = $(
          "#tableRow" + i + "_" + j + " " + "#ToMinute" + index
        ).val();
        let ToAMPM = $(
          "#tableRow" + i + "_" + j + " " + "#ToAmPm" + index
        ).val();
        let startTime = this.formatAMPM(
          result[i].date,
          fromHH,
          fromMM,
          FromAMPM
        );
        let endTime = this.formatAMPM(result[i].date, ToHH, ToMM, ToAMPM);
        if (
          fromHH !== "0" &&
          fromHH != null &&
          fromMM !== "0" &&
          fromMM != null &&
          FromAMPM !== "0" &&
          FromAMPM != null &&
          ToHH !== "0" &&
          ToHH != null &&
          ToMM !== "0" &&
          ToMM != null &&
          ToAMPM !== "0" &&
          ToAMPM != null
        ) {
          timeSlotArrayOne.push({
            apply:
              $("#tableRow" + i + "_" + j + " " + "#apply" + index).prop(
                "checked"
              ) == true
                ? true
                : false,
            scheduleStartTime: new Date(startTime).getTime(),
            scheduleEndTime: new Date(endTime).getTime(),
            thresholdLimit: result[i].dateFull,
            dayId:
              moment(result[i].dateFull, "YYYY-MM-DD").weekday() === 0
                ? 6
                : moment(result[i].dateFull, "YYYY-MM-DD").weekday() - 1,
          });
        }
      }
    }
    timeSlotArray = timeSlotArrayOne;
  };

  submitSchedule = () => {
    this.saveHandle();
    if (timeSlotArray.length > 0) {
      let validationFlag = true;
      for (let i = 0; i < timeSlotArray.length; i++) {
        if (
          timeSlotArray[i].scheduleStartTime > timeSlotArray[i].scheduleEndTime
        ) {
          alert("Please enter correct To Time");
          validationFlag = false;
          break;
        } else {
          validationFlag = true;
        }
      }
      if (validationFlag == true) {
        $(".loader").show();
        const apiJson = {
          method: "POST",
          body: JSON.stringify({
            functionName: "saveDoctorSchedule",
            doctorId: scheduledData.doctorId,
            hospitalId:hospitalId,
            userId: window.localStorage.getItem("userId"),
            hospitalDoctorVideoScheduleId:
              scheduledData.hospitalDoctorVideoScheduleId,
            currentTime: new Date().getTime(),
            scheduleList: timeSlotArray,
          }),
        };
        apiCalling(apiJson).then((data) => {
          if (data.success === "1") {
            $("#popup1").hide();
            $(".popOverlay").css("background-color", "transparent");
            this.getHospitalSpecialityList();
            this.setState({ doctorScheduleDataNew: [], addNewDiv: [] });
            timeSlotArray = [];
            scheduledData = [];
            $(".table-doctrworkListBorder .doctroffceInput").val("0");
            $(".table-doctrworkListBorder .doctroffceInputCheck").prop(
              "checked",
              false
            );
          }
          if (data.success === "0") {
            $(".loader").hide();
            // this.setState({doctorScheduleDataNew:[], addNewDiv:[]})
            // timeSlotArray = []
            // scheduledData = []
            alert(data.errorMessage);
          }
        });
      }
    } else {
      alert("Please enter correct To Time");
    }
  };

  handleTimeChange = (event, index, name) => {
    //this.dataPush(index)
  };
  editScheduleData = (data) => {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getDoctorSchedule",
        hospitalId:hospitalId,
        hospitalDoctorVideoScheduleId: data.hospitalDoctorVideoScheduleId,
        doctorId: data.doctorId,
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        let dataSet = [];
        let dataSetData = [];
        if (data.result.doctorScheduleDay.length > 0) {
          for (i = 0; i < result.length; i++) {
            dataSet = data.result.doctorScheduleDay
              .filter((date) => {
                if (date.applyAll == false) {
                  return (
                    result[i].date ==
                    moment(date.fromTime).format("DD-MMM-YYYY")
                  );
                } else {
                  return result[i].day == date.scheduleDayName.slice(0, 3);
                }
              })
              .map((item) => item);
            dataSetData.push({
              date: result[i].date,
              day: result[i].day,
              dateMilli: result[i].dateMilli,
              dataSet,
            });
          }
          this.setState(
            {
              doctorScheduleDataNew: dataSetData,
            },
            () => {
              console.log("dataSetData", dataSetData);
            }
          );
          dataSetData = [];
        } else {
          for (i = 0; i < result.length; i++) {
            dataSetData.push({
              date: result[i].date,
              day: result[i].day,
              dateMilli: result[i].dateMilli,
              dataSet,
            });
          }
          this.setState({
            doctorScheduleDataNew: dataSetData,
          });
          dataSetData = [];
        }
        $(".loader").hide();
      }
      if (data.success === "0") {
        this.setState({
          doctorScheduleDataNew: [],
        });
        $(".loader").hide();
        //alert(data.errorMessage)
      }
    });
  };
  editSchedule = (data) => {
    $(".loader").show();
    $("#popup1").show();
    $(".popOverlay").css("background-color", "rgba(0, 0, 0, 0.6)");
    this.setState({
      doctorName: data.doctorName,
      doctorSpeciality: data.speciality,
    });
    scheduledData = data;
    this.editScheduleData(data);
  };

  addNewTemplate(index, dataSetLength) {
    return (
      <div
        className={"table_inside tableRow" + index + "_" + dataSetLength}
        id={"tableRow" + index + "_" + dataSetLength}
      >
        <div className="tablehead-hspmng col-md-5">
          <div className="doctroffcehrDayRow">
            <div className="doctroffcehrDay">
              <select
                className="doctroffceInput"
                id={"Hours" + index}
                name={"Hours" + index}
                onChange={() => this.handleTimeChange(index, "Hours")}
              >
                <option value="0">HH</option>
                {(() => {
                  const options = [];
                  for (let i = 1; i <= 12; i++) {
                    options.push(<option value={i}>{i}</option>);
                  }
                  return options;
                })()}
              </select>
            </div>
            <div className="doctroffcehrDay">
              <select
                className="doctroffceInput"
                id={"Minute" + index}
                name={"Minute" + index}
                onChange={() => this.handleTimeChange(index, "Minute")}
              >
                <option value="0">MM</option>
                <option value="00">00</option>
                <option value="30">30</option>
              </select>
            </div>
            <div className="doctroffcehrDay">
              <select
                className="doctroffceInput"
                id={"AmPm" + index}
                name={"AmPm" + index}
                onChange={() => this.handleTimeChange(index, "AmPm")}
              >
                <option value="0">AM/PM</option>
                <option value="AM">AM</option>
                <option value="PM">PM</option>
              </select>
            </div>
          </div>
        </div>
        <div className="tablehead-hspmng col-md-4">
          <div className="doctroffcehrDayRow">
            <div className="doctroffcehrDay">
              <select
                className="doctroffceInput"
                id={"ToHours" + index}
                name={"ToHours" + index}
                onChange={() => this.handleTimeChange(index, "ToHours")}
              >
                <option value="0">HH</option>
                {(() => {
                  const options = [];
                  for (let i = 1; i <= 12; i++) {
                    options.push(<option value={i}>{i}</option>);
                  }
                  return options;
                })()}
              </select>
            </div>
            <div className="doctroffcehrDay">
              <select
                className="doctroffceInput"
                id={"ToMinute" + index}
                name={"ToMinute" + index}
                onChange={() => this.handleTimeChange(index, "ToMinute")}
              >
                <option value="0">MM</option>
                <option value="00">00</option>
                <option value="30">30</option>
              </select>
            </div>
            <div className="doctroffcehrDay">
              <select
                className="doctroffceInput"
                id={"ToAmPm" + index}
                name={"ToAmPm" + index}
                onChange={() => this.handleTimeChange(index, "ToAmPm")}
              >
                <option value="0">AM/PM</option>
                <option value="AM">AM</option>
                <option value="PM">PM</option>
              </select>
            </div>
          </div>
        </div>
        <div className="tablehead-hspmng textCenter col-md-1">
          <div className="doctrcheckboxDivhsp">
            <input
              className="doctroffceInputCheck"
              type="checkbox"
              id={"apply" + index}
              name="appType"
            //onChange={this.handleInputChange}
            />
            <label className="pt-0"></label>
          </div>
        </div>
        <div className="tablehead-hspmng col-md-2">
          <div className="textCenter">
            <span id="tom" className="mr-2 editbtn-qual">
              <img
                src={deactvate}
                alt=""
                width="18"
                className="img-fluid"
                onClick={() =>
                  this.deleteTimeSchedule(index, dataSetLength, "NewDelete", "")
                }
              />
            </span>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="purpleWrap bg-clr-wthHead">
        <Suspense fallback={<div></div>}>
          <section>
            <Header /> <div className="loader"></div>
          </section>
        </Suspense>

        <div className="container mb-5">
          <div>
            <ul className="breadcrum-adminMangmnt">
              <li>
                <a href="/admin/dashboardAdmin">Dashboard</a>
              </li>
              <li>
                <a href="/adminManagement/hospitalManagementList/hospitalManagementList">
                  Hospital Management
                </a>
              </li>
              <li>
                <a href="" className="current">
                  Doctor Video/Audio Schedule
                </a>
              </li>
            </ul>
          </div>

          <div className="DoctorworkListTable">
            {this.state.specialityList.length > 0 ? (
              <div className="table-responsive">
                <table className="table table-bordered-hsp table-doctrworkList">
                  <thead>
                    <tr>
                      <th className="tablehead-hspmng">Doctor Name</th>
                      <th className="tablehead-hspmng">Speciality </th>
                      <th className="tablehead-hspmng">Video Chat</th>
                      <th className="tablehead-hspmng">Charge/Session</th>
                      <th className="tablehead-hspmng">
                        Avg Session time (Minute)
                      </th>
                      <th className="tablehead-hspmng">Audio Chat</th>
                      <th className="tablehead-hspmng">Audio Chat Price</th>
                      <th className="tablehead-hspmng">Schedule</th>
                      <th className="tablehead-hspmng">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.specialityList.map((data, index) => {
                      return (
                        <tr>
                          <td>{data.doctorName}</td>
                          <td>{data.speciality}</td>
                          <td className="textCenter">
                            <div className="doctrcheckboxDivhsp">
                              <input
                                type="checkbox"
                                id={"videoType" + index}
                                name="appType"
                                onChange={(e) =>
                                  this.handleVideoChange(e, data, index)
                                }
                                defaultChecked={data.hasVideoChat}
                                defaultValue={data.hasVideoChat}
                              />
                              <label className="pt-0"></label>
                            </div>
                          </td>
                          <td>
                            <input
                              type="text"
                              className="hspInfofieldinput hspInfofieldinputSmall"
                              defaultValue={data.ratePerSession}
                              onChange={(e) =>
                                this.handleInputChange(e, data, index)
                              }
                              id={"ratePerSession" + index}
                              disabled={
                                data.hasVideoChat == true ? false : true
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              className="hspInfofieldinput hspInfofieldinputSmall"
                              defaultValue={data.averageSessionTime}
                              onChange={(e) =>
                                this.handleInputChange(e, data, index)
                              }
                              id={"averageSessionTime" + index}
                            />
                          </td>
                          <td className="textCenter">
                            <div className="doctrcheckboxDivhsp">
                              <input
                                type="checkbox"
                                id={"audioType" + index}
                                name="appType"
                                onChange={(e) =>
                                  this.handleAudioChange(e, data, index)
                                }
                                defaultChecked={data.hasAudioChat}
                                defaultValue={data.hasAudioChat}
                              />
                              <label className="pt-0"></label>
                            </div>
                          </td>
                          <td>
                            <input
                              type="text"
                              className="hspInfofieldinput hspInfofieldinputSmall"
                              defaultValue={data.ratePerSessionAudio}
                              onChange={(e) =>
                                this.handleInputChange(e, data, index)
                              }
                              id={"ratePerAudioSession" + index}
                              disabled={
                                data.hasAudioChat == true ? false : true
                              }
                            />
                          </td>
                          <td>
                            {data.isScheduleCreated === 0 ? (
                              <span
                                className="listLink-hspmangmnt"
                                onClick={() => this.createSchedule(data)}
                              >
                                Create Schedule
                              </span>
                            ) : (
                              <span
                                className="listLink-hspmangmnt"
                                onClick={() => this.editSchedule(data)}
                              >
                                Edit Schedule
                              </span>
                            )}
                          </td>
                          <td className="nowrap">
                            <div className="textCenter">
                              <span className="mr-2 editbtn-qual">
                                <img
                                  src={deactvate}
                                  alt=""
                                  width="18"
                                  className="img-fluid"
                                  onClick={() =>
                                    this.deleteSchedule(
                                      data.hospitalDoctorVideoScheduleId
                                    )
                                  }
                                />
                              </span>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td>
                        <select
                          className="hspInfofieldinput hspInfofieldinputSmall"
                          id="selectDoctor"
                          name="selectDoctor"
                          onChange={(e) => this.doctorChange(e)}
                        >
                          <option value="0">Select Doctor</option>
                          {this.state.notExistsDoctorData.map((item) => (
                            <option value={item.value + "_" + item.speciality}>
                              {item.label}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td>{this.state.docSpeciality}</td>
                      <td className="textCenter">
                        <div className="doctrcheckboxDivhsp">
                          <input
                            type="checkbox"
                            id={"videoType"}
                            name="videoType"
                            onChange={this.handleVideoAdd}
                          />
                          <label className="pt-0"></label>
                        </div>
                      </td>
                      <td>
                        <input
                          type="text"
                          className="hspInfofieldinput hspInfofieldinputSmall"
                          id="ChargeSession"
                          disabled={this.state.VideoAdd}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="hspInfofieldinput hspInfofieldinputSmall"
                          id="averagePrice"
                        />
                      </td>
                      <td className="textCenter">
                        <div className="doctrcheckboxDivhsp">
                          <input
                            type="checkbox"
                            id={"audioType"}
                            name="audioType"
                            onChange={this.handleAudioAdd}
                          />
                          <label className="pt-0"></label>
                        </div>
                      </td>
                      <td>
                        <input
                          type="text"
                          className="hspInfofieldinput hspInfofieldinputSmall"
                          id="AudioChatPrice"
                          disabled={this.state.AudioAdd}
                        />
                      </td>
                      <td></td>
                      <td className="nowrap">
                        <div className="textCenter">
                          <span
                            className="butnsave-qual addButtonSec"
                            onClick={() => this.addScheduleNew()}
                          >
                            Add
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="table-responsive">
                <table className="table table-bordered-hsp table-doctrworkList">
                  <thead>
                    <tr>
                      <th className="tablehead-hspmng">Doctor Name</th>
                      <th className="tablehead-hspmng">Speciality </th>
                      <th className="tablehead-hspmng">Video Chat</th>
                      <th className="tablehead-hspmng">Charge/Session</th>
                      <th className="tablehead-hspmng">
                        Avg Session time (Minute)
                      </th>
                      <th className="tablehead-hspmng">Audio Chat</th>
                      <th className="tablehead-hspmng">Audio Chat Price</th>
                      <th className="tablehead-hspmng">Schedule</th>
                      <th className="tablehead-hspmng">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <select
                          className="hspInfofieldinput hspInfofieldinputSmall"
                          id="selectDoctor"
                          name="selectDoctor"
                          onChange={(e) => this.doctorChange(e)}
                        >
                          <option value="0">Select Doctor</option>
                          {this.state.notExistsDoctorData.map((item) => (
                            <option value={item.value + "_" + item.speciality}>
                              {item.label}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td>{this.state.docSpeciality}</td>
                      <td className="textCenter">
                        <div className="doctrcheckboxDivhsp">
                          <input
                            type="checkbox"
                            id={"videoType"}
                            name="videoType"
                          //onChange={this.handleInputChange}
                          />
                          <label className="pt-0"></label>
                        </div>
                      </td>
                      <td>
                        <input
                          type="text"
                          className="hspInfofieldinput hspInfofieldinputSmall"
                          id="ChargeSession"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="hspInfofieldinput hspInfofieldinputSmall"
                          id="averagePrice"
                        />
                      </td>
                      <td className="textCenter">
                        <div className="doctrcheckboxDivhsp">
                          <input
                            type="checkbox"
                            id={"audioType"}
                            name="audioType"
                          //onChange={this.handleInputChange}
                          />
                          <label className="pt-0"></label>
                        </div>
                      </td>
                      <td>
                        <input
                          type="text"
                          className="hspInfofieldinput hspInfofieldinputSmall"
                          id="AudioChatPrice"
                        />
                      </td>
                      <td></td>
                      <td className="nowrap">
                        <div className="textCenter">
                          <span
                            className="butnsave-qual addButtonSec"
                            onClick={() => this.addScheduleNew()}
                          >
                            Add
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
            {this.state.isChangeVisible == true ? (
              <div className="popupbutndiv">
                <button
                  className="butnsave-qual"
                  id="addQualificationSave"
                  onClick={() => this.submitNewSchedule()}
                >
                  Save
                </button>
                <button
                  className="butncancel-qual"
                  onClick={() => this.submitScheduleCancel()}
                >
                  cancel
                </button>
              </div>
            ) : null}
          </div>
        </div>
        <div className="popBack" id="popup1">
          <div className="popOverlay" onClick={this.category1PopClose}></div>
          <div className="customizationPop customizationPopLarge">
            <div className="popuphead-qualifctn popuphead-qualifctn-bottom">
              <h2>
                Create Schedule- {this.state.doctorName},{" "}
                {this.state.doctorSpeciality}
              </h2>
            </div>
            <span
              className="closepopupqual"
              type="button"
              onClick={() => this.closepopup()}
            >
              <img src={Close} alt="Close" className="img-fluid" width="25" />
            </span>
            <div className="popupdiv">
              <div className="table-responsive">
                <div className="table table-bordered-hsp table-doctrworkListBorder">
                  <div className="table table-bordered-Head">
                    <div className="tablehead-hspmng col-md-1">Date</div>
                    <div className="tablehead-hspmng col-md-1">Day</div>
                    <div className="tablehead-hspmng col-md-1">&nbsp;</div>
                    <div className="tablehead-hspmng col-md-9">
                      <div className="table_inside">
                        <div className="tablehead-hspmng col-md-5">From</div>
                        <div className="tablehead-hspmng col-md-4">To</div>
                        <div className="tablehead-hspmng col-md-1">
                          Apply All
                        </div>
                        <div className="tablehead-hspmng col-md-2">Actions</div>
                      </div>
                    </div>
                  </div>

                  <div className="table table-bordered-body">
                    {this.state.doctorScheduleDataNew.length > 0 ? (
                      <div>
                        {this.state.doctorScheduleDataNew.map((item, index) => {
                          let parentIndex = index;
                          return (
                            <div className="table table-bordered-row">
                              <div className="tablehead-hspmng col-md-1">
                                {item.date}
                              </div>
                              <div className="tablehead-hspmng col-md-1">
                                {item.day}{" "}
                              </div>
                              <div className="tablehead-hspmng col-md-1">
                                <div className="textCenter">
                                  <span className="mr-2 editbtn-qual">
                                    <img
                                      src={plus}
                                      alt=""
                                      width="18"
                                      className="img-fluid"
                                      onClick={() =>
                                        this.scheduleEdit(
                                          index,
                                          item.dataSet.length,
                                          item.date
                                        )
                                      }
                                    />
                                  </span>
                                </div>
                              </div>
                              <div className="tablehead-hspmng col-md-9">
                                <input
                                  type="text"
                                  id={"dataSet" + index}
                                  defaultValue={item.dataSet.length}
                                  style={{ display: "none" }}
                                />
                                {item.dataSet.length > 0 ? (
                                  <div className="table_insideInner">
                                    {item.dataSet.map((item, index) => {
                                      let hours = moment(item.fromTime).format(
                                        "h"
                                      );
                                      let minute = moment(item.fromTime).format(
                                        "mm"
                                      );
                                      let Am = moment(item.fromTime).format(
                                        "A"
                                      );
                                      let tohours = moment(item.toTime).format(
                                        "h"
                                      );
                                      let tominute = moment(item.toTime).format(
                                        "mm"
                                      );
                                      let toAm = moment(item.toTime).format(
                                        "A"
                                      );
                                      let index1 = index + 1;
                                      return (
                                        <div
                                          className="table_inside"
                                          id={
                                            "tableRow" +
                                            parentIndex +
                                            "_" +
                                            index1
                                          }
                                        >
                                          <input
                                            type="hidden"
                                            id={"fromTime" + index}
                                            value={item.fromTime}
                                          />
                                          <div className="tablehead-hspmng col-md-5">
                                            <div className="doctroffcehrDayRow">
                                              <div className="doctroffcehrDay">
                                                <select
                                                  defaultValue={hours}
                                                  className="doctroffceInput"
                                                  id={"Hours" + parentIndex}
                                                  name={"Hours" + index}
                                                  onChange={(e) =>
                                                    this.handleTimeChange(
                                                      e,
                                                      index,
                                                      "Hours"
                                                    )
                                                  }
                                                >
                                                  <option value="0">HH</option>
                                                  {(() => {
                                                    const options = [];
                                                    for (
                                                      let i = 1;
                                                      i <= 12;
                                                      i++
                                                    ) {
                                                      options.push(
                                                        <option value={i}>
                                                          {i}
                                                        </option>
                                                      );
                                                    }
                                                    return options;
                                                  })()}
                                                </select>
                                              </div>
                                              <div className="doctroffcehrDay">
                                                <select
                                                  className="doctroffceInput"
                                                  defaultValue={minute}
                                                  id={"Minute" + parentIndex}
                                                  name={"Minute" + index}
                                                  onChange={(e) =>
                                                    this.handleTimeChange(
                                                      e,
                                                      index,
                                                      "Minute"
                                                    )
                                                  }
                                                >
                                                  <option value="0">MM</option>
                                                  <option value="00">00</option>
                                                  <option value="30">30</option>
                                                </select>
                                              </div>
                                              <div className="doctroffcehrDay">
                                                <select
                                                  className="doctroffceInput"
                                                  defaultValue={Am}
                                                  id={"AmPm" + parentIndex}
                                                  name={"AmPm" + index}
                                                  onChange={(e) =>
                                                    this.handleTimeChange(
                                                      e,
                                                      index,
                                                      "AmPm"
                                                    )
                                                  }
                                                >
                                                  <option value="0">
                                                    AM/PM
                                                  </option>
                                                  <option value="AM">AM</option>
                                                  <option value="PM">PM</option>
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="tablehead-hspmng col-md-4">
                                            <div className="doctroffcehrDayRow">
                                              <div className="doctroffcehrDay">
                                                <select
                                                  className="doctroffceInput"
                                                  defaultValue={tohours}
                                                  id={"ToHours" + parentIndex}
                                                  name={"ToHours" + index}
                                                  onChange={(e) =>
                                                    this.handleTimeChange(
                                                      e,
                                                      index,
                                                      "ToHours"
                                                    )
                                                  }
                                                >
                                                  <option value="0">HH</option>
                                                  {(() => {
                                                    const options = [];
                                                    for (
                                                      let i = 1;
                                                      i <= 12;
                                                      i++
                                                    ) {
                                                      options.push(
                                                        <option value={i}>
                                                          {i}
                                                        </option>
                                                      );
                                                    }
                                                    return options;
                                                  })()}
                                                </select>
                                              </div>
                                              <div className="doctroffcehrDay">
                                                <select
                                                  className="doctroffceInput"
                                                  defaultValue={tominute}
                                                  id={"ToMinute" + parentIndex}
                                                  name={"ToMinute" + index}
                                                  onChange={(e) =>
                                                    this.handleTimeChange(
                                                      e,
                                                      index,
                                                      "ToMinute"
                                                    )
                                                  }
                                                >
                                                  <option value="0">MM</option>
                                                  <option value="00">00</option>
                                                  <option value="30">30</option>
                                                </select>
                                              </div>
                                              <div className="doctroffcehrDay">
                                                <select
                                                  className="doctroffceInput"
                                                  defaultValue={toAm}
                                                  id={"ToAmPm" + parentIndex}
                                                  name={"ToAmPm" + index}
                                                  onChange={(e) =>
                                                    this.handleTimeChange(
                                                      e,
                                                      index,
                                                      "ToAmPm"
                                                    )
                                                  }
                                                >
                                                  <option value="0">
                                                    AM/PM
                                                  </option>
                                                  <option value="AM">AM</option>
                                                  <option value="PM">PM</option>
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="tablehead-hspmng textCenter col-md-1">
                                            <div className="doctrcheckboxDivhsp">
                                              <input
                                                className="doctroffceInputCheck"
                                                type="checkbox"
                                                id={"apply" + parentIndex}
                                                name="apply"
                                                onChange={(e) =>
                                                  this.handleCheckChange(
                                                    e,
                                                    index,
                                                    item.applyAll
                                                  )
                                                }
                                                defaultChecked={item.applyAll}
                                                defaultValue={item.applyAll}
                                              />
                                              <label className="pt-0"></label>
                                            </div>
                                          </div>
                                          <div className="tablehead-hspmng col-md-2">
                                            <div className="textCenter">
                                              <span className="mr-2 editbtn-qual">
                                                <img
                                                  src={deactvate}
                                                  alt=""
                                                  width="18"
                                                  className="img-fluid"
                                                  onClick={() =>
                                                    this.deleteTimeSchedule(
                                                      index,
                                                      parentIndex,
                                                      "ExistingDelete",
                                                      item
                                                    )
                                                  }
                                                />
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                    {this.state.addNewDiv
                                      .filter(
                                        (data) =>
                                          "tableRow" + index ==
                                          data[0].props.id.split("_")[0]
                                      )
                                      .map((item) => {
                                        return item;
                                      })}
                                  </div>
                                ) : (
                                  <div className="table_insideInner">
                                    {this.addNewTemplate(
                                      index,
                                      item.dataSet.length === 0
                                        ? 1
                                        : item.dataSet.length
                                    )}
                                    {this.state.addNewDiv
                                      .filter(
                                        (data) =>
                                          "tableRow" + index ==
                                          data[0].props.id.split("_")[0]
                                      )
                                      .map((item) => {
                                        return item;
                                      })}
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <div>No Data Found</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="popupbutndiv">
                <button
                  className="butnsave-qual"
                  id="addQualificationSave"
                  onClick={() => this.submitSchedule()}
                >
                  Save
                </button>
                {/* <button
                  className="butnsave-qual"
                  id="UpdateDetails"
                  onClick={() => this.submitSchedule()}
                >
                  Update
                </button> */}
                <button
                  className="butncancel-qual"
                  onClick={() => this.closepopup()}
                >
                  cancel
                </button>
              </div>
            </div>
          </div>
        </div>

        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
