import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import { apiCalling } from "../../apiService";
import $ from "jquery";
import AWS from "aws-sdk";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import moment from "moment";
const animatedComponents = makeAnimated();
var s3;
var fileNameUploadDrImage;
var isVerifiedFlag = true;

const OfficialInformation = React.forwardRef((props, ref) => {
  const [stateData, setStateData] = useState([]);
  const [associationData, setAssociationData] = useState([]);
  const [activeDate, setActiveDate] = useState(new Date());
  const [deactiveDate, setDeactiveDate] = useState(
    new Date().setDate(new Date().getDate() + 366)
  );
  const [fileDrImage, setFileDrImage] = useState(null);
  const [registrationDate, setRegistrationDate] = useState(null);
  const [createdOnDate, setCreatedOnDate] = useState(null);
  const [paidFromDate, setPaidFromDate] = useState(new Date());
  const [paidToDate, setPaidToDate] = useState(new Date());
  const [s3bucketAccessKey, setS3bucketAccessKey] = useState("");
  const [s3bucketSecretKey, setS3bucketSecretKey] = useState("");
  const [s3BucketMainFolder, setS3BucketMainFolder] = useState("");
  const [s3BucketAddressPath, setS3BucketAddressPath] = useState("");
  const [s3BucketDoctorImages, setS3BucketDoctorImages] = useState("");
  const [s3Bucket, setS3Bucket] = useState("");
  const [associationSelectedOption, setAssociationSelectedOption] = useState(
    null
  );
  const [chkAcceptMobileAppOnly, setChkAcceptMobileAppOnly] = useState(false);
  useEffect(() => {
    $("#cmbExcludeFromPH").val("true");
    $("#txtSortOrder").val("999");
    $("#txtAdvertisementId").val("999");
    $("#txtFreeAQAvailableCount").val("1000");
    $("#cmbAQNumFollowUp").val("0");
    $("#cmbAQPrice").val("0");
    $("#txtRating").val("0");
    $("#chkAcceptOnlineAppointment").prop("checked", true);
    $("#chkPulsePaymentDone").prop("checked", true);

    $("#chkAcceptMobileAppOnly").prop("checked", true);
    listing();
    if (props.doctorData.length === 0) {
      dataLoad();
    }
    if (props.doctorData.length > 0) {
      if (props.doctorData[0].isVerified === false) {
        isVerifiedFlag = false;
      }
    }
  }, []);

  useEffect(() => {
    if (props.doctorData.length > 0) {
      $("#activeDate").val(props.doctorData[0].activeDate);
      $("#deactiveDate").val(props.doctorData[0].deactiveDate);
      registratiomState(props.doctorData[0].registrationstate);
      $("#cmbPracticeStartYear").val(
        props.doctorData[0].practice_start_year !== ""
          ? props.doctorData[0].practice_start_year
          : "0"
      );
      $("#txtMedRegistrationNo").val(
        props.doctorData[0].medicalRegNumber
      );
      $("#cmbNewStatus").val(
        props.doctorData[0].status === true ? "true" : "false"
      );
      $("#cmbMedTourism").val(
        props.doctorData[0].isMedicalTourism === true ? "true" : "false"
      );
      $("#cmbIsVideoorAudio").val(
        props.doctorData[0].isVideoEnabled === true ? "true" : "false"
      );
      $("#cmbIsVerified").val(
        props.doctorData[0].isVerified === true ? "true" : "false"
      );
      // alert( props.doctorData[0].isVerified," props.doctorData[0].isVerified")
      $("#cmbIsEHREnabled").val(
        props.doctorData[0].isEhrEnabled === true ? "true" : "false"
      );
      $("#cmbIsVaasEnabled").val(
        props.doctorData[0].isVaasEnabled === true ? "true" : "false"
      );
      $("#registrationDate").val(props.doctorData[0]?.registrationDate ? props.doctorData[0]?.registrationDate : null);
      setPaidFromDate(props.doctorData[0]?.paidFromDate && props.doctorData[0]?.paidFromDate !== "" ? props.doctorData[0]?.paidFromDate : null)
      setPaidToDate(props.doctorData[0]?.paidToDate && props.doctorData[0]?.paidToDate !== "" ? props.doctorData[0]?.paidToDate : null)
      // setPaidToDate(props.doctorData[0]?.paidToDate)
      // console.log(props.doctorData[0]?.paidFromDate, props.doctorData[0]?.paidToDate, "ooooooooooooooooooooooooooooooooo");
      setChkAcceptMobileAppOnly(props.doctorData[0].mobileAppOnly)
      $("#txtDoctorId").val(props.doctorData[0].doctorId);
      $("#txtCreatedBy").val(props.doctorData[0].createdBy);
      $("#txtCreatedOn").val(props.doctorData[0].createdOn);
      $("#txtModifiedBy").val(props.doctorData[0].modifiedBy);
      $("#txtModifiedOn").val(props.doctorData[0].modifiedOn);
      $("#txtSortOrder").val(props.doctorData[0].sortOrder);
      $("#txtAdvertisementId").val(props.doctorData[0].advertisementId);
      $("#txtRating").val(props.doctorData[0].rating);
      $("#cmbAskQuestion").val(
        props.doctorData[0].askQuestion === true ? "true" : "false"
      );
      $("#cmbIsAssisted").val(
        props.doctorData[0].isAssistedEnabled === true ? "true" : "false"
      );
      $("#cmbAQNumFollowUp").val(props.doctorData[0].aqNumFollowUp);
      $("#cmbPhoneConsulting").val(
        props.doctorData[0].isPhoneConsultAvail === true ? "true" : "false"
      );
      $("#cmbAQPrice").val(props.doctorData[0].aqPrice);
      $("#txtPhoneConsultingPrice").val(
        props.doctorData[0].phoneConsultPrice
      );
      $("#cmbIsFreeAQ").val(
        props.doctorData[0].isFreeAq === true ? "true" : "false"
      );
      $("#txtInPersonConsultationPrice").val(
        props.doctorData[0].consultationPrice
      );
      $("#txtFreeAQAvailableCount").val(
        props.doctorData[0].freeAqAvailCount
      );
      $("#cmbIsAccountLocked").val(
        props.doctorData[0].isAccountLocked === true ? "true" : "false"
      );
      $("#cmbInPersonAppoinment").val(
        props.doctorData[0].hasInPersonAppointment === true ? "true" : "false"
      );
      $("#cmbExcludeFromPH").val(
        props.doctorData[0].isExcludeFromPh === true ? "true" : "false"
      );
      if (props.doctorData[0].isAcceptOnline === true) {
        $("#chkAcceptOnlineAppointment").prop("checked", true);
      } else {
        $("#chkAcceptOnlineAppointment").prop("checked", false);
      }
      // if (props.doctorData[0].pmPaymentDone === true) {
      //   $("#chkPulsePaymentDone").prop("checked", true);
      // } else {
      //   $("#chkPulsePaymentDone").prop("checked", false);
      // }
      // if (props.doctorData[0].mobileAppOnly == true) {
      //   $("#chkAcceptMobileAppOnly").prop("checked", true);
      // } else {
      //   $("#chkAcceptMobileAppOnly").prop("checked", false);
      // }
      if (props.doctorData[0].imageNameOnly !== "") {
        $("#imgDiv1").show();
        $("#image1").attr(
          "src",
          props.doctorData[0].imageNameWithPath
        );
      }
    }
  }, [props.doctorData]);
  useEffect(() => {
    dataLoad()
  }, [props.getDropdownLists])
  useEffect(() => {
    let assoc = [];
    props.getHospitalDropdown.associationData.map((d) =>
      assoc.push({
        value: d.associationId,
        label: d.associationName + " - " + d.associationId,
      })
    );
    let associationSelectedOption = [];
    props.associationEdit.map((d) =>
      associationSelectedOption.push({
        value: d,
        label: assoc.find((element) => element.value === d).label,
      })
    );
    setAssociationData(assoc);
    setAssociationSelectedOption(associationSelectedOption);
    if (props.doctorData.length > 0) {
      dataLoad();
    }

  }, [props.associationEdit])
  const dataLoad = () => {
    setStateData(props.getDropdownLists.stateData);
    setS3bucketAccessKey(props.getDropdownLists.s3bucketAccessKey);
    setS3bucketSecretKey(props.getDropdownLists.s3bucketSecretKey);
    setS3BucketMainFolder(props.getDropdownLists.S3BucketMainFolder);
    setS3BucketAddressPath(props.getDropdownLists.s3BucketAddressPath);
    setS3BucketDoctorImages(props.getDropdownLists.S3BucketDoctorImages);
    setS3Bucket(props.getDropdownLists.s3Bucket);
    AWS.config.update({
      accessKeyId: props.getDropdownLists.s3bucketAccessKey,
      secretAccessKey: props.getDropdownLists.s3bucketSecretKey,
      region: "us-east-1",
    });
    var bucketParams = {
      Bucket: props.getDropdownLists.s3Bucket,
    };
    s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
  };

  const uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (
      c
    ) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };

  // const handleCheckboxChange = () => {
  //   var isChecked = $('#chkPulsePaymentDone').prop('checked');
  //  // alert(isChecked);
  //   var fromDate=$('#paidFromDate').val();
  //   var toDate=$('#paidToDate').val();
  //    if (isChecked===false) {
  //     $('#paidFromDate').val('');
  //     $('#paidToDate').val('');
  //     $('#paidFromDate').prop('disabled', true);
  //     $('#paidToDate').prop('disabled', true);
  //   }
  //   else {
  //     $('#paidFromDate').prop('disabled', false);
  //     $('#paidToDate').prop('disabled', false);
  //   }
  // };
  const registratiomState = (registrationstate) => {
    if (registrationstate !== "" || registrationstate !== 0) {
      let regStateObj = stateData.filter(
        (o) => o.stateName === registrationstate
      );
      if (regStateObj.length > 0) {
        $("#cmbRegistrationState").val(regStateObj[0].stateId);
      }
    } else {
      $("#cmbRegistrationState").val(0);
    }
  };

  const listing = () => {
    console.log(  props.getHospitalDropdown.associationData,"  props.getHospitalDropdown.hospitalData[0].associationData");
    console.log(props.associationEdit,"llllllllllllllllllllllllllllllllllll");
    yearDropdown();
  };

  const yearDropdown = () => {
    var ddlYears = document.getElementById("cmbPracticeStartYear");
    var currentYear = new Date().getFullYear();
    var option1 = document.createElement("OPTION");
    option1.innerHTML = "Select";
    option1.value = "0";
    if (ddlYears !== null && ddlYears !== "null") {
      ddlYears.appendChild(option1);
      for (var i = currentYear; i >= 1950; i--) {
        var option = document.createElement("OPTION");
        option.innerHTML = i;
        option.value = i;
        ddlYears.appendChild(option);
      }
    }
  };

  const handleChange = (event) => {
    const re = /^\d{0,10}(\.\d{0,6})?$/;
    if (event.target.value === "" || !re.test(event.target.value)) {
      var a = event.target.value.toString().slice(0, -1);
      $("#" + event.target.id).val(a);
      if (!re.test($("#" + event.target.id).val())) {
        $("#" + event.target.id).val("");
      }
      alert("Only Numbers Accepted");
    } else {
      return true;
    }
  };

  const handleChangeDateActive = (date) => {
    setActiveDate(date);
  };

  const disableDateField = () => {
    $("#activeDate").attr("readonly", "readonly");
    $("#deactiveDate").attr("readonly", "readonly");
    setActiveDate(null);
    setDeactiveDate(null);
    setRegistrationDate(null);
  };

  const handleChangeDateDeactive = (date) => {
    setDeactiveDate(date);
  };

  const handleChangeRegistration = (date) => {
    setRegistrationDate(date);
  };

  const handleChangeCreatedDate = (date) => {
    setCreatedOnDate(date);
  };

  const handleImageUpload = (e, mode) => {
    if (typeof e.target.files[0] !== "undefined") {
      let file = e.target.files[0];
      let fileType = file["type"];
      let validImageTypes = ["image/jpg", "image/jpeg", "image/png"];
      if (!validImageTypes.includes(fileType)) {
        alert("Invalid file type");
        $("#" + mode + "File").val(null);
        if (mode === "doctorImg") {
          $("#image1").attr("src", "");
          $("#imgDiv1").hide();
        }
      } else {
        var fileObj = e.target.files[0];
        let reader = new FileReader();
        var img = new Image();
        img.src = window.URL.createObjectURL(fileObj);
        img.onload = function () {
          reader.onload = function (e) {
            if (mode === "doctorImg") {
              $("#image1").attr("src", e.target.result);
              $("#imgDiv1").show();
            }
          };
          reader.readAsDataURL(fileObj);
        };
        if (mode === "doctorImg") {
          setFileDrImage(fileObj);
        }
      }
    }
  };

  const fileUpload = (file, mode) => {
    let reader = new FileReader();
    let s3BucketMainFolder1 =
      s3BucketMainFolder + "/" + s3BucketDoctorImages;
    let fileName = uuidv4() + file.name;
    let fullFilePath = s3BucketMainFolder1 + "/" + fileName;
    fileNameUploadDrImage = fileName;
    if (mode === "doctorImg") {
      fileNameUploadDrImage = fileName;
    }
    s3.upload(
      {
        Key: fullFilePath,
        Body: file,
        ACL: "public-read",
      },
      (err, data) => {
        if (err) {
          return null;
        } else {
          return data.Location
        }
      }
    );
  };

  const enableAskQuestion = () => {
    if ($("#cmbAskQuestion").val() === "true") {
      $("#cmbAQNumFollowUp").attr("disabled", false);
      $("#cmbAQPrice").attr("disabled", false);
    } else {
      $("#cmbAQNumFollowUp").attr("disabled", true);
      $("#cmbAQPrice").attr("disabled", true);
    }
  };

  const phoneConsultingEnable = () => {
    if ($("#cmbPhoneConsulting").val() === "true") {
      $("#txtPhoneConsultingPrice").attr("disabled", false);
    } else {
      $("#txtPhoneConsultingPrice").attr("disabled", true);
    }
  };

  const officialInformationData = () => {
    var image = "";
    if (fileDrImage !== null) {
      fileUpload(fileDrImage, "doctorImg");
      image = fileNameUploadDrImage;
    } else if (
      props.doctorData.length > 0 &&
      props.doctorData[0].imageNameOnly !== ""
    ) {
      image = props.doctorData[0].imageNameOnly;
    }
    return image;
  };

  const handleVerificationChange = () => {
    if ($("#cmbIsVerified").val() === "true" && isVerifiedFlag === false) {
      props.isVerifiedChange(isVerifiedFlag);
    }
  };
  React.useImperativeHandle(ref, () => ({
    officialInformationData: () => {
      const imagePath = officialInformationData();
      return imagePath; // Return the image path directly
    }
  }));
  useEffect(() => {
    if (chkAcceptMobileAppOnly) {
      setPaidFromDate(null);
      setPaidToDate(null);
    }
  }, [chkAcceptMobileAppOnly]);

  const handleCheckboxChange = (e) => {
    setChkAcceptMobileAppOnly(e.target.checked);
  };

  const handleChangePaidFromDate = (date) => {
    setPaidFromDate(date);
  };

  const handleChangePaidToDate = (date) => {
    setPaidToDate(date);
  };

  const convertDateFormat = (dateString) => {
    if (dateString == null || dateString == "") return null
    // Parse the input date string with "DD/MM/YYYY" format
    const parsedDate = moment(dateString, 'DD/MM/YYYY');

    // Format and return the parsed date as "MM-DD-YYYY"
    return parsedDate.format('MM-DD-YYYY');
  };
  return (
    <div className="bg-clr-wthHead offcialinfowrapperhsp-bg mb-3">
      <section>
        <div className="hspAdmindetails-wrapper pb-4 pt-3">
          <h2 className="hspInfoSubhead pb-3">Official Information</h2>
          <div className="hspinfo-contentdiv  react-date-dctr-width">
            <label>Active Date</label>
            <DatePicker
              placeholderText="Active Date"
              id="activeDate"
              name="activeDate"
              showMonthDropdown
              className="doctrHsp-dateinput"
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={100}
              dropdownMode="scroll"
              peekNextMonth
              disabledKeyboardNavigation
              selected={activeDate}
              onChange={handleChangeDateActive}
              dateFormat="dd/MM/yyyy"
              calendarClassName="react-date-picker"
              autoComplete="off"
            />
          </div>
          <div className="hspinfo-contentdiv  react-date-dctr-width">
            <label>
              Deactive Date <span className="hspifoMantory">*</span>
            </label>
            <DatePicker
              placeholderText="Deactive Date "
              id="deactiveDate"
              name="deactiveDate"
              showMonthDropdown
              className="doctrHsp-dateinput"
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={100}
              dropdownMode="scroll"
              peekNextMonth
              disabledKeyboardNavigation
              selected={deactiveDate}
              onChange={handleChangeDateDeactive}
              dateFormat="dd/MM/yyyy"
              calendarClassName="react-date-picker"
              autoComplete="off"
            />
          </div>
          <div className="hspinfo-contentdiv">
            <label>Registration State</label>
            <select
              name="cmbRegistrationState"
              id="cmbRegistrationState"
              className="hspInfofieldinput Infoinputwidthdoctr"
            >
              <option value="0">Select</option>
              {stateData.map((stateObj, i) => {
                return (
                  <option key={stateObj.stateId} value={stateObj.stateId}>
                    {stateObj.stateName}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="hspinfo-contentdiv">
            <label>Practice Start Year</label>
            <select
              name="cmbPracticeStartYear"
              id="cmbPracticeStartYear"
              className="hspInfofieldinput Infoinputwidthdoctr"
            ></select>
          </div>
          <div className="hspinfo-contentdiv">
            <label>Medical Register No.</label>
            <input
              type="text"
              id="txtMedRegistrationNo"
              name="txtMedRegistrationNo"
              className="hspInfofieldinput Infoinputwidthdoctr"
              placeholder="Medical Register No."
            />
          </div>
          <div className="superadminDivBoxcover">
            <div className="superadminDivBox">
              <label>Image(jpeg,png)</label>
              <div className="hspinfoimgaeuploadDiv">
                <div className="hspinfo-btnupload">
                  <span>Upload</span>
                  <input
                    type="file"
                    id="doctorImg"
                    accept="image/*"
                    name="doctorImg"
                    className="hspinfoInputfieldupload"
                    onChange={(e) => handleImageUpload(e, "doctorImg")}
                  />
                </div>
                <div id="imgDiv1" style={{ display: "none" }}>
                  {" "}
                  <img height="30" width="35" id="image1"></img>
                </div>
                <hidden id="hidDoctorImage" name="hidDoctorImage"></hidden>
              </div>
            </div>
          </div>
          <div className="hspinfo-contentdiv">
            <label>Status</label>
            <select
              name="cmbNewStatus"
              id="cmbNewStatus"
              className="hspInfofieldinput Infoinputwidthdoctr"
            >
              <option value="true">Enabled</option>
              <option value="false">Disabled</option>
            </select>
          </div>
          <div className="hspinfo-contentdiv">
            <label>Medical Tourism</label>
            <select
              name="cmbMedTourism"
              id="cmbMedTourism"
              className="hspInfofieldinput Infoinputwidthdoctr"
            >
              <option value="false">Disabled</option>
              <option value="true">Enabled</option>
            </select>
          </div>
          <div
            className="superadminDivBoxcover"
            style={{ overflow: "visible" }}
          >
            <div className="hspinfo-contentdiv association-input-doctr">
              <label className="pt-0">
                Association Name <span className="hspifoMantory">*</span>
              </label>
              {console.log(associationSelectedOption,"associationSelectedOptionassociationSelectedOption")}
              {console.log(associationData,"associationDataassociationDataassociationData")}
              <Select
                value={associationSelectedOption}
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                options={associationData}
                onChange={(selectedOption) => {
                  props.setAssociations(
                    selectedOption.map((a) => a.value)
                  );
                  setAssociationSelectedOption(selectedOption)
                }}
              />
            </div>
          </div>
          <div className="hspinfo-contentdiv hspinfo-contentdivwidthres">
            <label>Is Video/Audio Enabled</label>
            <select
              name="cmbIsVideoorAudio"
              id="cmbIsVideoorAudio"
              className="hspInfofieldinput hspInfofieldinputWidth"
            >
              <option value="false">No</option>
              <option value="true">Yes</option>
            </select>
          </div>
          <div className="hspinfo-contentdiv hspinfo-contentdivwidthres">
            <label>Is Verified</label>
            <select
              name="cmbIsVerified"
              id="cmbIsVerified"
              className="hspInfofieldinput hspInfofieldinputWidth"
              onChange={() => handleVerificationChange()}
            >
              <option defaultValue={true} value="true">Yes</option>
              <option value="false">No</option>
            </select>
          </div>
          <div className="hspinfo-contentdiv hspinfo-contentdivwidthres">
            <label>Is EHR Enabled</label>
            <select
              name="cmbIsEHREnabled"
              id="cmbIsEHREnabled"
              className="hspInfofieldinput hspInfofieldinputWidth"
            >
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
          </div>
          <div className="hspinfo-contentdiv hspinfo-contentdivwidthres">
            <label>Is vaas Enabled</label>
            <select
              name="cmbIsVaasEnabled"
              id="cmbIsVaasEnabled"
              className="hspInfofieldinput hspInfofieldinputWidth"
            >
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
          </div>
          <div className="superadminDivBoxcover">
            <div className="superadminDivBox">
              <div className="doctrcheckboxDivhsp">
                <input
                  type="checkbox"
                  id="chkAcceptOnlineAppointment"
                  name="chkAcceptOnlineAppointment"
                />
                <label className="pt-0">Accept online Appointment</label>
              </div>
              {/* <div className="doctrcheckboxDivhsp">
                    <input
                      type="checkbox"
                      id="chkPulsePaymentDone"
                      name="chkPulsePaymentDone"
          onChange={handleCheckboxChange}
                    />
                    <label className="pt-0">Pulse Payment Done</label>
                  </div> */}
              <div className="doctrcheckboxDivhsp">
                <input
                  type="checkbox"
                  id="chkAcceptMobileAppOnly"
                  name="chkAcceptMobileAppOnly"
                  checked={chkAcceptMobileAppOnly}
                  onChange={handleCheckboxChange}
                />
                <label className="pt-0">Mobile App Only</label>
              </div>
              {/* <p>Please UnCheck Mobile App Only To Add Dates</p> */}
            </div>
          </div>
          <div className="hspinfo-contentdiv react-date-dctr-width">
            <label>Paid From Date</label>
            <DatePicker
              disabled={chkAcceptMobileAppOnly}
              placeholderText="Paid From Date"
              id="paidFromDate"
              name="paidFromDate"
              minDate={new Date()}
              showMonthDropdown
              className="doctrHsp-dateinput"
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={10}
              dropdownMode="scroll"
              peekNextMonth
              disabledKeyboardNavigation
              selected={convertDateFormat(paidFromDate)}
              onChange={handleChangePaidFromDate}
              dateFormat="dd/MM/yyyy"
              calendarClassName="react-date-picker"
              autoComplete="off"
            />
          </div>
          <div className="hspinfo-contentdiv react-date-dctr-width">
            <label>Paid To Date</label>
            <DatePicker
              disabled={chkAcceptMobileAppOnly}
              placeholderText="Paid To Date"
              id="paidToDate"
              name="paidToDate"
              minDate={new Date()}
              showMonthDropdown
              className="doctrHsp-dateinput"
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={10}
              dropdownMode="scroll"
              peekNextMonth
              disabledKeyboardNavigation
              selected={convertDateFormat(paidToDate)}
              onChange={handleChangePaidToDate}
              dateFormat="dd/MM/yyyy"
              calendarClassName="react-date-picker"
              autoComplete="off"
            />
          </div>

          <div className="hspinfo-contentdiv react-date-dctr-width">
            <label>Registration Date</label>
            <DatePicker
              placeholderText="Registration Date"
              id="registrationDate"
              name="registrationDate"
              maxDate={new Date()}
              showMonthDropdown
              className="doctrHsp-dateinput"
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={100}
              dropdownMode="scroll"
              peekNextMonth
              disabledKeyboardNavigation
              selected={registrationDate ? new Date(registrationDate) : null}
              onChange={handleChangeRegistration}
              dateFormat="dd/MM/yyyy"
              calendarClassName="react-date-picker"
              autoComplete="off"
            // onKeyDown={() => this.disableDateField()}
            />
          </div>
          <div className="hspinfo-contentdiv">
            <label>Doctor Id</label>
            <input
              type="text"
              id="txtDoctorId"
              name="txtDoctorId"
              className="hspInfofieldinput"
              placeholder="Doctor Id"
              disabled
            />
          </div>
          <div className="hspinfo-contentdiv">
            <label>Created By</label>
            <input
              type="text"
              id="txtCreatedBy"
              name="txtCreatedBy"
              value={window.localStorage.getItem("userId")}
              className="hspInfofieldinput"
              placeholder="Created By"
              disabled
            />
          </div>
          <div className="hspinfo-contentdiv react-date-dctr-width">
            <label>Created On</label>
            <DatePicker
              placeholderText="Active Date"
              disabled
              id="txtCreatedOn"
              name="txtCreatedOn"
              showMonthDropdown
              className="doctrHsp-dateinput"
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={100}
              dropdownMode="scroll"
              peekNextMonth
              disabledKeyboardNavigation
              selected={createdOnDate ? new Date(createdOnDate) : null}
              onChange={handleChangeCreatedDate}
              dateFormat="dd/MM/yyyy"
              calendarClassName="react-date-picker"
              autoComplete="off"
            />
          </div>
          <div className="hspinfo-contentdiv">
            <label>Modified By</label>
            <input
              type="text"
              value={window.localStorage.getItem("userId")}
              id="txtModifiedBy"
              name="txtModifiedBy"
              className="hspInfofieldinput"
              placeholder="Modified By"
              disabled
            />
          </div>
          <div className="hspinfo-contentdiv">
            <label>Modified On</label>
            <input
              type="text"
              id="txtModifiedOn"
              name="txtModifiedOn"
              className="hspInfofieldinput"
              placeholder="Modified On"
              disabled
            />
          </div>
          <div className="hspinfo-contentdiv">
            <label>Sort Order</label>
            <input
              type="text"
              id="txtSortOrder"
              name="txtSortOrder"
              className="hspInfofieldinput"
              placeholder="Sort Order"
              onKeyUp={handleChange}
              onChange={handleChange}
            />
          </div>
          <div className="hspinfo-contentdiv">
            <label>Advertisement ID</label>
            <input
              type="text"
              id="txtAdvertisementId"
              name="txtAdvertisementId"
              className="hspInfofieldinput"
              placeholder="Advertisement ID"
              onKeyUp={handleChange}
              onChange={handleChange}
            />
          </div>
          <div className="superadminDivBoxcover">
            <div className="hspinfo-contentdiv">
              <label className="pt-0">Rating</label>
              <input
                type="text"
                id="txtRating"
                name="txtRating"
                className="hspInfofieldinput"
                placeholder="Rating"
                onKeyUp={handleChange}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="hspinfo-contentdiv hspinfo-contentdivwidthres">
            <label>Ask Question</label>
            <select
              name="cmbAskQuestion"
              id="cmbAskQuestion"
              className="hspInfofieldinput hspInfofieldinputWidth"
              onChange={() => enableAskQuestion()}
            >
              <option value="false">No</option>
              <option value="true">Yes</option>
            </select>
          </div>
          <div className="hspinfo-contentdiv hspinfo-contentdivwidthres">
            <label>Is Assisted</label>
            <select
              name="cmbIsAssisted"
              id="cmbIsAssisted"
              className="hspInfofieldinput hspInfofieldinputWidth"
            >
              <option value="false">No</option>
              <option value="true">Yes</option>
            </select>
          </div>
          <div className="hspinfo-contentdiv hspinfo-contentdivwidthres">
            <label>AQ Num Follow up</label>
            <input
              type="text"
              id="cmbAQNumFollowUp"
              name="cmbAQNumFollowUp"
              className="hspInfofieldinput"
              placeholder="AQ Num Follow up"
              onKeyUp={handleChange}
              onChange={handleChange}
              disabled
            />
          </div>
          <div className="hspinfo-contentdiv hspinfo-contentdivwidthres">
            <label>Phone Consulting</label>
            <select
              name="cmbPhoneConsulting"
              id="cmbPhoneConsulting"
              className="hspInfofieldinput hspInfofieldinputWidth"
              onChange={() => phoneConsultingEnable()}
            >
              <option value="false">No</option>
              <option value="true">Yes</option>
            </select>
          </div>
          <div className="hspinfo-contentdiv hspinfo-contentdivwidthres">
            <label>AQ Price</label>
            <input
              type="text"
              id="cmbAQPrice"
              name="cmbAQPrice"
              className="hspInfofieldinput"
              placeholder="AQ Price"
              onKeyUp={handleChange}
              onChange={handleChange}
              disabled
            />
          </div>
          <div className="hspinfo-contentdiv hspinfo-contentdivwidthres">
            <label>Phone Consulting Price</label>
            <input
              type="text"
              id="txtPhoneConsultingPrice"
              name="txtPhoneConsultingPrice"
              className="hspInfofieldinput"
              placeholder="Phone Consulting Price"
              onKeyUp={handleChange}
              onChange={handleChange}
              disabled
            />
          </div>
          <div className="hspinfo-contentdiv hspinfo-contentdivwidthres">
            <label>Is Free AQ</label>
            <select
              name="cmbIsFreeAQ"
              id="cmbIsFreeAQ"
              className="hspInfofieldinput hspInfofieldinputWidth"
            >
              <option value="false">No</option>
              <option value="true">Yes</option>
            </select>
          </div>
          <div className="hspinfo-contentdiv hspinfo-contentdivwidthres">
            <label>In Person Consultation Price</label>
            <input
              type="text"
              id="txtInPersonConsultationPrice"
              name="txtInPersonConsultationPrice"
              className="hspInfofieldinput"
              placeholder="In Person Consultation Price"
              onKeyUp={handleChange}
              onChange={handleChange}
            />
          </div>
          <div className="hspinfo-contentdiv hspinfo-contentdivwidthres">
            <label>Free AQ Available Count</label>
            <input
              type="text"
              id="txtFreeAQAvailableCount"
              name="txtFreeAQAvailableCount"
              className="hspInfofieldinput"
              placeholder="Free AQ Available Count"
              onKeyUp={handleChange}
              onChange={handleChange}
            />
          </div>
          <div className="hspinfo-contentdiv hspinfo-contentdivwidthres">
            <label>InPerson Appoinment</label>
            <select
              name="cmbInPersonAppoinment"
              id="cmbInPersonAppoinment"
              className="hspInfofieldinput hspInfofieldinputWidth"
            >
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
          </div>
          <div className="hspinfo-contentdiv hspinfo-contentdivwidthres">
            <label>Exclude from Doctor's Cabin</label>
            <select
              name="cmbExcludeFromPH"
              id="cmbExcludeFromPH"
              className="hspInfofieldinput hspInfofieldinputWidth"
            >
              <option value="false">No</option>
              <option value="true">Yes</option>
            </select>
          </div>
        </div>
      </section>
    </div>
  );
})
export default OfficialInformation;