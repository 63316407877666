import React, { Component } from 'react';
import Header from "../layout/header"
import Footer from "../layout/footer"
import Banner from "../image/theme/dental-banner.png"
import Ben1 from "../image/theme/icon1.png"
import Ben2 from "../image/theme/icon2.png"
import KeyFeatures from './key-features-dental';
export default class Medical extends Component {


  render() {
    return (
      <div className="ph-main-wrapper">
        <Header></Header>
        <div className='row d-flex align-items-center m-0'>
          <div className="col-12 col-lg-6 pl-md-5">
            <div className='text-center px-4'>
              <h1 class="ph-main-title-2 mt-4">Clinic Management Software For Dentists</h1>
              <p className="lead text-left">
              Revolutionize your dental practice with PurpleHealth’s cutting-edge Clinic Management Software. 

              </p>
            </div>
          </div>
          <div className="col-12 col-lg-6 p-0">
            <img src={Banner} alt="Banner" width="100%" height="600" className='img-fluid' />
          </div>
        </div>
        <div className='benefits-section pt-5 pb-2 py-lg-5 py-md-5'>
          <div className="container">
            <h2 class="ph-main-title-2-grey text-center pb-lg-2 pb-0">Designed by dentists for dentists!</h2>
            <div className="row m-0 px-lg-0 px-md-0 pb-5 px-0">
              <div className="col-xl-8 col-lg-8 col-md-10 mb-lg-0 mb-5 m-auto text-center">               
                <div>Streamline your operations with features like automated appointment scheduling, comprehensive patient records, and seamless billing integration. Enhance patient care with digital X-ray storage, treatment planning tools, and reminders for regular check-ups. Stay on top of your practice's performance with real-time analytics and reporting. Our intuitive, user-friendly interface ensures that you can focus on what matters most—providing exceptional dental care. Experience the future of dental practice management today!

                </div>
              </div>
            </div>
          </div>
        </div>
      <KeyFeatures/>
        <div className='plan-section py-5'>
          <div className="container mb-lg-5 mb-0">
            <h2 class="ph-main-title-2 text-center">Choose One of our Amazing Plans!</h2>
            <div className='row'>
              <div className='col-md-4 mb-4 mb-md-0'>
                <div className='col-md-12 card plan-list'>
                  <div class="card-body px-0 d-flex flex-column">
                    <h2 className='plan-list-head'>Practice Management App</h2>
                    <div className='plan-list-rate pl-3'>
                      <span class="badge badge-primary mt-2">Free</span>
                    </div>
                    <ul className='plan-list-ul'>
                      <li>For single practitioners</li>
                      <li>Includes prescriptions, billing, patient records</li>
                      <li>iOS & Android</li>
                      <li>Integrated Telehealth - Video & Chat consults</li>
                      <li>Secure & Private data with 256 Bit SSL Encrytion</li>
                    </ul>
                    </div>
                </div>
              </div>
              <div className='col-md-4 mb-4 mb-md-0'>
                <div className='col-md-12 card plan-list'>
                  <div class="card-body px-0 d-flex flex-column">
                    <h2 className='plan-list-head'>Clinic Management System</h2>
                    <div className='plan-list-rate pl-3'>
                      <span class="badge badge-primary mt-2">Rs. 11,999*</span>
                    </div>
                    <ul className='plan-list-ul'>
                      <li>Full featured CMS including prescriptions, billing, electronic patient health records and all key features</li>
                      <li>Multiple dentists and clinics</li>
                      <li>Staff access and administration</li>
                      <li>Reporting and Analytics</li>
                      <li>Fully private patient data and secure file storage with 256 Bit SSL Encryption</li>

                    </ul>
                    <div className='medical_special'>*Special price available. Contact us today!</div>
                    </div>
                </div>
              </div>
              <div className='col-md-4 mb-4 mb-md-0'>
                <div className='col-md-12 card plan-list'>
                  <div class="card-body px-0 d-flex flex-column">
                    <h2 className='plan-list-head'>Website </h2>
                    <div className='plan-list-rate pl-3'>
                      <span class="badge badge-primary mt-2">Rs. 11,999*</span>
                    </div>
                    <ul className='plan-list-ul'>
                      <li> Your own branding, colours & logo</li>
                      <li>Integrated Patient Booking</li>
                      <li>SEO Friendly to improve search engine rank</li>
                      <li>Content system to add blogs, images and videos</li>    
                    </ul>
                    <div className='medical_special'>*Special price available. Contact us today!</div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    )
  }
}
